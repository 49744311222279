import React from "react";
// Customizable Area Start
import { Box, Button, InputAdornment, TextField, Typography, styled } from '@material-ui/core';
import { AppHeader } from '../../../components/src/AppHeader.web';
import {
  img1475048925HTpe556x7cBoLvN2ZuRRme,
  img1475048939HTpe556x7cBoLvN2ZuRRme,
  img962c7d73186b2cd6f245df742bac938b,

  imgArrowRight,
  imgI14750489301945250HTpe556x7cBoLvN2ZuRRme,
  imgI14750489311945250HTpe556x7cBoLvN2ZuRRme,
  imgI1475048969142885542HTpe556x7cBoLvN2ZuRRme,
  imgI1475048970142885542HTpe556x7cBoLvN2ZuRRme,
  imgI1475048971142885542HTpe556x7cBoLvN2ZuRRme,
  imgI1475048972142885542HTpe556x7cBoLvN2ZuRRme
} from './assets';
import { Autocomplete } from '@material-ui/lab';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { GalleryBlock } from '../../../components/src/GalleryBlock.web';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LandingPageController from './LandingPageController';

const TypographyView = styled(Typography)({
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .MuiAutocomplete-option:hover': {
    backgroundColor: '#D9EEEB'
  },
  '& .MuiButton-label': {
    textTransform: 'none'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .search-block': {
    background: `url(${img1475048925HTpe556x7cBoLvN2ZuRRme}) no-repeat center/cover`,
    maxHeight: '587px',
    minHeight: '450px',
  },
  '& .landing-block': {
    padding: '113px 141px',
  },
  '& .about-block': {
    background: '#ffffff',
    margin: '77px 0',
    padding: '70px 141px'
  },
  '& .info-page-block': {
    fontFamily: 'Ubuntu',
  },
  '& .carousel__inner-slide img': {
    borderRadius: '8px'
  },
  '& .carousel__slide--hidden img': {
    height: '70%',
    animation: '0.2s linear $zoom-in'
  },
  '& .carousel__slide--visible img': {
    height: '100%',
    animation: '0.2s linear $zoom-out'
  },
  '& .carousel__slider-tray': {
    marginLeft: '50%'
  },
  '& .carousel__inner-slide': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .carousel__slider-tray-wrapper': {
    width: '50vw'
  },
  '@global': {
    '@keyframes zoom-in': {
      '0%': {
        height: '100%'
      },
      '100%': {
        height: '70%',
      }
    },
    '@keyframes zoom-out': {
      '0%': {
        height: '70%',
      },
      '100%': {
        height: '100%'
      }
    },
    '*::-webkit-scrollbar': {
      width: '5px'
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#D9EEEB',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#398378',
      outline: '1px solid slategrey'
    }
  }
});

interface Props {
  navigation: any;
  id: string;
}

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

    this.goToCategoryVenues = this.goToCategoryVenues.bind(this);
  }

  async componentDidMount() {
    this.getLandingPageData();
  }

  handleProfileClick = () => {
    this.props.navigation.navigate('SpaUserProfile');
  };

  render() {
    const { about_us, products } = this.state.landingPageData;
    const couponSlides = this.getResultVouchers();
    // Custom style sheets
    const styles = {
      SafeAreaView: {
        backgroundColor: '#e8f6f4ff'
      },
      inputSearch: {
        borderRadius: '8px',
        borderBottom: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
        width: '17vw'
      }
    };
    // Component code
    const profilePic = this.state.userDetails?.attributes?.image_url || "";    
    return (
      <TypographyView>
        <Box style={styles.SafeAreaView} className='info-page-block'>
          <AppHeader onClickEvent={() => this.goToLogin()} onProfileClick={this.handleProfileClick} profileImageUrl={profilePic}/>
          <Box
            data-view-name={{ viewName: 'group_' }}
          >
            <Box className='search-block landing-block'>
              <Typography
                style={{
                  width: 400, 
                  fontFamily: 'Ubuntu',
                  fontWeight: '700',
                  fontSize: 48,
                  color: '#ffffffff',
                }}
              >{`Find a Service close to you`}</Typography>
              <Typography
                style={{
                  padding: '30px 0',
                  width: 551,
                  fontFamily: 'Ubuntu',
                  fontWeight: '400',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                }}
              >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. .`}</Typography>
              <Box height={83} display='flex' justifyContent='center' alignItems='center' borderRadius='12px' width='50vw' gridGap={10} style={{ backgroundColor: '#ffffff' }}>
                <Box>
                  <Typography style={{ color: '#398378', fontSize: '14px', fontWeight: '700' }}>
                    Spa Name
                  </Typography>
                  <Autocomplete
                    id="company-search"
                    options={[
                      'The Zen Company',
                      'The Massage Studio',
                      'The Science of Beauty Spa',
                      'Thermal Thoughts',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                      'Touch n Glow Spa',
                    ]}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    disablePortal
                    freeSolo
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          placeholder='Search by Name'
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img width={20} height={20} src={imgI14750489301945250HTpe556x7cBoLvN2ZuRRme} alt="" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                          style={{
                            ...styles.inputSearch
                          }}
                        />
                      </div>
                    )}
                  />

                </Box>
                <Box>
                  <Typography style={{ color: '#398378', fontSize: '14px', fontWeight: '700' }}>
                    Location
                  </Typography>
                  <Autocomplete
                    id="location-search"
                    options={[
                      'Cambodia',
                      'Canada',
                      'Cameroon',
                      'Cabo Verde',
                      'China',
                      'China',
                      'China',
                      'China',
                      'China',
                      'China',
                      'China'
                    ]}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    disablePortal
                    freeSolo
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          placeholder='Search by Location'
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img width={20} height={20} src={imgI14750489311945250HTpe556x7cBoLvN2ZuRRme} alt="" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                          style={{
                            ...styles.inputSearch
                          }}
                        />
                      </div>
                    )}
                  />
                </Box>
                <Button
                  style={{ height: 59, width: 130, borderRadius: 8, backgroundColor: '#398378ff', color: '#ffffffff' } as React.CSSProperties}
                >Search</Button>
              </Box>

            </Box>

            <Box className='landing-block about-block' display='flex' justifyContent='space-between'>
              <Box>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontSize: '40px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    color: '#000000ff',
                    marginBottom: '32px'
                  }}
                > About Us </Typography>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: '400',
                    fontSize: 16,
                    color: '#000000ff',
                    marginBottom: '50px',
                    marginRight: '16px'
                  }}
                >{about_us.description}</Typography>
                <Button
                  style={{ height: 56, width: 163, borderRadius: 8, backgroundColor: '#398378ff', color: '#ffffffff' }}
                >Learn More</Button>
              </Box>
              <img
                style={{ width: '48%', display: 'block' }}
                src={img1475048939HTpe556x7cBoLvN2ZuRRme}
              />
            </Box>

            {/* TODO: change the image */}
            <Box pt={5} pb={7} style={{
              background: `url(${img962c7d73186b2cd6f245df742bac938b}) center/cover`,
              textAlign: 'center',
            }}>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: '700',
                  fontSize: 48,
                  color: '#ffffffff',
                  marginBottom: '40px'
                }}
              >{`Explore exclusive discount vouchers!`}</Typography>
              <Box>
                {/* Advertisement carousel */}
                <CarouselProvider
                  infinite
                  naturalSlideWidth={70}
                  naturalSlideHeight={38.5}
                  totalSlides={couponSlides.length}
                  currentSlide={1}
                >
                  <Slider>
                    {couponSlides.map((couponImg, index) =>
                      <Slide key={couponImg + index} index={index}>
                        <img src={couponImg} alt="advertisement image" />
                      </Slide>)}
                  </Slider>
                </CarouselProvider>
              </Box>
            </Box>

            <Box className='landing-block' style={{ paddingBottom: '48px' }}>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: '40px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  color: '#000000ff',
                  marginBottom: '32px'
                }}
              > Find a Service close to you </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: '400',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#000000ff',
                  marginBottom: '40px',
                  wordBreak: 'break-all'
                }}
              >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. .`}</Typography>

              <GalleryBlock
                products={products}
                buttons={[
                  {
                    title: 'Explore',
                    handler: this.goToCategoryVenues.bind(this)
                  }
                ]}
              />

            </Box>

            <Box className='landing-block' style={{
              background: `url(${img962c7d73186b2cd6f245df742bac938b}) center/cover`,
              textAlign: 'center'
            }}>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: '700',
                  fontSize: 48,
                  color: '#ffffffff',
                }}
              >{`"Own Your Serenity and Join Us!"`}</Typography>
              <Typography
                style={{
                  padding: '30px 0',
                  fontFamily: 'Ubuntu',
                  fontWeight: '400',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                }}
              >
                {`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `}
              </Typography>
              <Button
                style={{
                  padding: '16px',
                  whiteSpace: 'pre',
                  borderRadius: 8,
                  backgroundColor: '#398378ff',
                  color: '#ffffffff',
                  width: '45%'
                } as React.CSSProperties}
                onClick={() => this.goToSpaRegistration()}
              >{`Join as a Spa Owner`}<img src={imgArrowRight} style={{ marginLeft: '32px' }} /></Button>
            </Box>

            <Box className='landing-block'>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: '40px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  color: '#000000ff',
                  marginBottom: '32px'
                }}
              >Why Choose us?</Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: '400',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#000000ff',
                  marginBottom: '40px'
                }}
              >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. .`}</Typography>

              <Box display='flex' gridGap='28px' flexWrap='wrap' justifyContent='center' textAlign='center'>
                <Box width='23%'>
                  <img src={imgI1475048969142885542HTpe556x7cBoLvN2ZuRRme} alt="service image" width='100%' />
                  <Typography
                    style={{
                      fontFamily: 'Ubuntu',
                      fontWeight: '400',
                      fontSize: 16,
                      marginTop: '25px'
                    }}>
                    Lorem ipsum
                  </Typography>
                </Box>
                <Box textAlign='center' width='23%'>
                  <img src={imgI1475048970142885542HTpe556x7cBoLvN2ZuRRme} alt="service image" width='100%' />
                  <Typography
                    style={{
                      fontFamily: 'Ubuntu',
                      fontWeight: '400',
                      fontSize: 16,
                      marginTop: '25px'
                    }}>
                    Lorem ipsum
                  </Typography>
                </Box>
                <Box textAlign='center' width='23%'>
                  <img src={imgI1475048971142885542HTpe556x7cBoLvN2ZuRRme} alt="service image" width='100%' />
                  <Typography
                    style={{
                      fontFamily: 'Ubuntu',
                      fontWeight: '400',
                      fontSize: 16,
                      marginTop: '25px'
                    }}>
                    Lorem ipsum
                  </Typography>
                </Box>
                <Box textAlign='center' width='23%'>
                  <img src={imgI1475048972142885542HTpe556x7cBoLvN2ZuRRme} alt="service image" width='100%' />
                  <Typography
                    style={{
                      fontFamily: 'Ubuntu',
                      fontWeight: '400',
                      fontSize: 16,
                      marginTop: '25px'
                    }}>
                    Lorem ipsum
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
    );
  }
}

// Customizable Area End
