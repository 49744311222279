import React from "react";

//Customizable Area Start
import { Box, Container, Button,ThemeProvider, Typography, createTheme, styled, } from "@material-ui/core";
import { backgroundImg, headerLogoImg } from "./assets";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const styles = {
  paperContainer: {
      backgroundImage: `url(${backgroundImg})`,
      width:'100%',
      height:'1920px',
      backgroundSize: 'contain',
  }
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const RecoveryPasswordView = styled(Box)({
  width: '50%',
  borderRadius: '24px 24px 0px 0px',
  background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  '& .mainView':{
    padding: '40px',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    background:'var(--Neutrals-Cool-gray-50, #F8FAFC)'
  },
  '& .testLabel':{
    paddingTop:'10px',
    paddingBottom:'10px',
  },
  '& .formHeader':{
    'display': 'flex',
    padding: '27px 172px 26px 172px',
    borderRadius: '24px 24px 0px 0px',
    background: 'var(--Primary, #398378)'
  },
  '& .logo':{
    width:'176px',
    height:'72px'
  },
  '& .btnStyle':{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 10px 0px",
  },
 
  '& .button':{
    width:'390px',
     background:'#398378',
     padding:'16px',
     textTransform:'none',
     color:'#FFF',
   },
   '& .MuiButton-textPrimary':{
       color:'#FFFFF'
   },
   '& .MuiBox-root-19':{
     color:'#1B5950 !important'
   },
   '& .MuiBox-root-14':{
     color:'#334155 !important'
   },
   '& .PrivateSwitchBase-root-18':{
     paddingLeft:'0px'
   },
   '& .forgotPwdText':{
     fontSize: "14px",
     color: "#1B5950",
     fontWeight: "400",
     marginTop: "5px",
   },
   '& .remember':{
     fontSize: "14px",
     color: "#334155",
     fontWeight: "400",
     marginTop: "5px",
   },
   '& .accountText':{
     fontSize:'16px',
     color:'#0F172A',
     fontWeight:400,
     fontFamily:'Ubuntu'
 
   },
  '& .testStar':{
    color:'red',
    paddingTop:'10px',
    paddingBottom:'10px',
    paddingLeft:'5px'
  },
  '& .inputeStyle':{
    borderRadius: '8px',
    border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
    border:'2px solid #398378 !important'
  },
  '& .signUp':{
    fontSize:'16px',
    color:'#0F172A',
    fontWeight:'bold',
    fontFamily:'Ubuntu',
    paddingLeft:'5px'

  },
  '& .MuiSnackbarContent-root':{
    background:'#FEE2E2',
    color:'#DC2626',
    borderLeft:'4px solid #DC2626',
    borderRadius: '4px',
    width: '405px',
  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    marginTop: '20px'
  },
  '& .MuiPaper-root':{
    background:'#D9EEEB',
    width:'180px',
    left:'860px'
  },
  '& .PrivateSwitchBase-root-25':{
    paddingLeft:'0px'
  },
  '& .passwordRecoveryView':{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:'space-between',
    padding: "10px 73px 0px 120px",
  }
  // MuiOutlinedInput-root.Mui-focused MuiOutlinedInput-notchedOutline

});
const TypographyView = styled(Typography)({
  '& .description':{
    fontSize:'16px',
    fontFamily:'Ubuntu',
    fontWeight:400,
    color:'#FFF'
  },
  '& .txtErrorMsg':{
    color:'#DC2626',
    fontSize:'12px',
    fontWeight:400,
    fontFamily:'Ubuntu',
    paddingTop:'5px'
  },
  '& .heading':{
  fontSize:'36px',
  fontFamily:'Ubuntu',
  fontWeight:700,
  color:'#FFF'
},

'& .title':{
  color: `var(--Basic-Black, #000)`,
fontFamily: 'Ubuntu',
fontSize: '24px',
fontWeight: 700,
},
'& .link':{
  color: '#0F172A',
  fontFamily: 'Ubuntu',
  fontSize: '16px',
  fontWeight: 400,
  paddingTop:'5px'
},
'& .emailTitle':{
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '18px',
    fontWeight: 700,
    paddingTop:'20px'
  },
'& .readAndAgree':{
  color:'#0F172A',
  fontFamily: 'Ubuntu',
  fontSize: '12px'
},
'& .termsAndCond':{
  color:'#50C',
  fontFamily: 'Ubuntu',
  fontSize: '12px',
  paddingRight:'2px',
  textDecoration:'underline'
}
});

//Customizable Area End

export default class RecoveryPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={styles.paperContainer}>
          <Box  className="passwordRecoveryView">
            <Box width="45%">
              <TypographyView>
                 <Typography className="heading"> {this.textHeading}</Typography>
                 <Typography className="description"> {this.description}</Typography>
              </TypographyView>
            </Box>
            <RecoveryPasswordView>
              <Box className="formHeader">
                <img src={`${headerLogoImg}`} className="logo" alt="Logo" />
              </Box>
              <Box className="mainView">
                <TypographyView>
                  <Typography className="title">{'Password Recovery'}</Typography>
                  <Typography className="emailTitle">{'Check your email'}</Typography>
                  <Typography className="link">{`We have sent a password reset link to email@email.com`}</Typography>
                </TypographyView>
                <Box  className="btnStyle">
                  <Button
                    data-test-id={"btnResendEmail"}
                    className="button"
                    onClick={this.onsubmit}
                  >
                    Resend E-mail  {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>
                <TypographyView>
                  <Box sx={{flexDirection:'row',display:'flex'}} pt={2} pb={2}>
                    <Typography className="account"> {'Back to'} </Typography><Typography className="signUp"> {'Log in'}</Typography>
                  </Box>
                </TypographyView>
                </Box>
            </RecoveryPasswordView>
           </Box>
        </Container>
      </ThemeProvider>
    );
  }
}