import React from "react";

// Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  createTheme,
  InputAdornment,
  InputLabel,
  Popover,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import * as Yup from "yup";

import UserProfileBasicController, {
  CategorySlotsError,
  Props,
  configJSON,
} from "./UserProfileBasicController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web";
import { Formik } from "formik";
import { Catalogue, initCatalogues } from "./EditServiceController.web";

import "../../../components/src/durationPicker.css";
import "../../../components/src/calendar.css";
import DatePicker from "react-multi-date-picker";
import { ButtonComponent } from "./styles";
import { Autocomplete } from "@material-ui/lab";
import DurationPicker from "react-duration-picker";
import { timeRangeArrow, calendarIcon, imgPendingClockGrey } from "./assets";
import { RegistrationFormView } from "../../../components/src/styles";
import { callAvailability } from '../../../blocks/utilities/src/call-availability';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Ubuntu",
    },
  },
});

const styles = {
  mainBox: {
    padding: "3rem 8.5rem 7rem",
    backgroundColor: "#E8F6F4",
  },
  formButton: {
    height: "56px",
    width: "auto",
    minWidth: "100px",
    padding: "16px",
  },
  formBlock: {
    backgroundColor: "#FFFFFF",
    padding: "2.5rem 2.5rem 1.5rem",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  },
  dayPartWrapper: {
    display: "inline-block",
    verticalAlign: "sub",
  },
  dayPart: {
    display: "block",
    fontFamily: "Ubuntu",
    fontSize: 16,
    marginLeft: 4,
  },
};

export const initAvailabilitiesValues = {
  categorySlots: [
    {
      id: 0,
      availabilities: [{
        availabilityId: 0,
        slotId: 0,
        timeSlots: []
      }],
      catalogueId: 0,
      serviceName: "",
      categoryName: "",
      capacity: 0,
      date: [],
      slots: [],
      slotData: {
        id: 0,
        duration: "",
      },
    },
  ],
};
// Customizable Area End

export default class EditAvailabilities extends UserProfileBasicController {
  calendarSlotRef: React.RefObject<{
    openCalendar: () => void;
    closeCalendar: () => void;
  }>[];
  availabilityStepLoad: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.availabilityStepLoad = React.createRef();
    this.calendarSlotRef = [React.createRef()];
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  }

  assignInitialValues = (setFieldValue: any) => {
    if (!this.availabilityStepLoad.current && this.state.services.length) {
      const categorySlots = this.state.services.map(
        (catalogue: Catalogue, index) => {
          // Create ref for each calendar per catalogue item
          index && this.calendarSlotRef.push(React.createRef());

          const attribs = catalogue.attributes;

          // Show firstly availability with existed duration
          const firstAvailability = attribs.availabilities.find(
            (availability) => availability.duration
          );

          const preAvailabilities = attribs.availabilities.map((availability) => ({
            availabilityId: availability.availability_id,
            timeSlots: availability.timeslots,
            slotId: availability.duration.id
          }));

          const initAvailabilities = catalogue.attributes.catalogue_slots.map((catalogue) => ({
            availabilityId: 0,
            timeSlots: [],
            slotId: catalogue.id
          }));

          const availabilities = preAvailabilities.length ? preAvailabilities : initAvailabilities;

          const initAttribs = initCatalogues[0].attributes;
          const { category, name } = attribs || initAttribs;
          const {
            capacity,
            dates,
            timeslots,
            duration: { id: slotId, duration: slotDuration },
          } = firstAvailability || initAttribs.availabilities[0];

          const timeStrings = this.generateTimeSlots(
            slotDuration,
            index,
            timeslots
          );
          let indexes: number[] = [];
          timeslots.forEach((timeSlot: string) => {
            indexes = this.pushOrRemoveIndex(
              indexes,
              timeStrings.indexOf(timeSlot)
            );
            // Mitigate set state dellay to execute it
            setTimeout(() => this.setSelectedSlotIndexes(indexes, slotId), 0);
          });
          const inputDates = dates.map((date: string) => new Date(date));
          this.setSlotDates(inputDates, index);

          return {
            availabilities,
            catalogueId: catalogue.id,
            serviceName: name,
            categoryName: category.attributes.name,
            capacity: capacity,
            date: dates,
            slotData: {
              id: slotId,
              duration: slotDuration,
            },
          };
        }
      );
      setFieldValue("categorySlots", categorySlots);

      this.availabilityStepLoad.current = true;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { durationFrom, durationTo } = this.state.durationPicker;
    return (
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Box style={styles.mainBox}>
          <Box display="flex" style={{ marginLeft: "0.5rem" }}>
            <BreadCrumbs
              items={["Home", "Spa Profile", "Set Availability"]}
              targetColor="#398378"
              navigationMap={this.navigationMap}
              navigate={this.props.navigation.navigate}
            />
          </Box>
          <Box mt={1.5} mb={2.5}>
            <Typography
              style={{
                fontSize: "2.5rem",
                fontWeight: 700,
                marginLeft: "0.5rem",
                letterSpacing: "-1.5%",
              }}
            >
              Set Availability
            </Typography>
          </Box>
          <Formik
            initialValues={initAvailabilitiesValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              categorySlots: Yup.array().of(
                Yup.object().shape({
                  date: Yup.array().required(configJSON.emptyDate),
                  slotData: Yup.object().shape({
                    duration: Yup.string().required(configJSON.emptyDuration),
                  }),
                  availabilities: Yup.array().of(
                    Yup.object().shape({
                      timeSlots: Yup.array().required(configJSON.emptyAvailableSlots)
                    }))
                })
              ),
            })}
            onSubmit={(values) => {
              callAvailability(values, this);
            }}
          >
            {({
              handleChange,
              submitForm,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              values,
            }) => {
              this.assignInitialValues(setFieldValue);
              return (
                <RegistrationFormView>
                  <Box>
                    <Box className="mainView" style={styles.formBlock}>
                      {values.categorySlots.map((categorySlot, index, array) => {
                        const {
                          date: dateError,
                          availabilities,
                          slotData,
                        } = (errors.categorySlots as CategorySlotsError[])?.[
                        index
                        ] || {};
                        const durationError = slotData?.duration;
                        // Retrieve any availability error if it's present
                        const availabilitiesError = String(availabilities?.find(
                          (availablity) => Boolean(availablity?.timeSlots)
                        )?.timeSlots);
                        const isAvailabilitiesError = values.categorySlots[index].availabilities.some(
                          (availability) => !availability?.timeSlots.length
                        );

                        const { capacity } = categorySlot;

                        const isDurationSelected =
                          !!values.categorySlots[index].slotData.id;

                        const categorySlotsItemTouched =
                          touched.categorySlots?.[index];
                        const isDateError = this.getInputError(
                          !!categorySlotsItemTouched?.date,
                          dateError
                        );
                        const isDurationError = this.getInputError(
                          categorySlotsItemTouched?.slotData?.duration,
                          durationError
                        );
                        const isSlotsError = this.getInputError(
                          !!categorySlotsItemTouched?.availabilities?.length,
                          isAvailabilitiesError
                        );

                        const dateProp = `categorySlots.${index}.date`;
                        const slotDataProp = `categorySlots.${index}.slotData`;
                        const slotProp = `categorySlots.${index}.slots`;
                        const availabilitiesProp = `categorySlots.${index}.availabilities`;

                        return (
                          <Accordion
                            key={categorySlot.id + index}
                            date-test-id="collapse"
                            style={{
                              border: "none",
                              boxShadow: "none",
                              padding: "1rem 0",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              style={{
                                fontSize: "1.5rem",
                                fontFamily: "Ubuntu",
                                fontWeight: 700,
                                color: "#0F172A",
                              }}
                            >
                              {categorySlot.categoryName}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box width="100%">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                  gridGap={8}
                                >
                                  <Box width="48%">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        paddingTop: "15px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <InputLabel className="testLabel">
                                        Service Name
                                      </InputLabel>
                                    </Box>
                                    <TextField
                                      name={`categorySlots.${index}.serviceName`}
                                      value={categorySlot.serviceName}
                                      disabled
                                      fullWidth={true}
                                      variant="outlined"
                                      className="inputeStyle"
                                      placeholder={"Enter service name"}
                                      InputProps={{
                                        style: { borderRadius: "8px" },
                                      }}
                                    />
                                  </Box>
                                  <Box width="48%">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        paddingTop: "15px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <InputLabel className="testLabel">
                                        Capacity
                                      </InputLabel>
                                    </Box>
                                    <TextField
                                      name={`categorySlots.${index}.capacity`}
                                      value={capacity || undefined}
                                      fullWidth={true}
                                      type="number"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      className="inputeStyle"
                                      data-test-id="capacity"
                                      placeholder={"Enter capacity"}
                                      InputProps={{
                                        style: { borderRadius: "8px" },
                                      }}
                                    />
                                  </Box>
                                  {/* DatePicker */}
                                  <Box width="48%" position="relative">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        paddingTop: "15px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <InputLabel className="testLabel">
                                        Service Availability
                                      </InputLabel>
                                      <span>&nbsp;</span>
                                      <InputLabel className="testStar">
                                        *
                                      </InputLabel>
                                    </Box>
                                    <Box position="relative">
                                      <img
                                        src={calendarIcon}
                                        alt="calendar"
                                        style={{
                                          position: "absolute",
                                          cursor: "pointer",
                                          height: 24,
                                          width: 24,
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          marginLeft: 8,
                                        }}
                                        onClick={
                                          this.calendarSlotRef[index].current
                                            ?.openCalendar
                                        }
                                      />
                                      <DatePicker
                                        key="date-picker-uniq329"
                                        className="basic-page-picker"
                                        ref={this.calendarSlotRef[index]}
                                        data-test-id="date-picker"
                                        placeholder="Add your service days"
                                        style={this.getErrorBorder(isDateError)}
                                        inputClass="input-date-style"
                                        format="ddd"
                                        multiple
                                        range
                                        buttons
                                        monthYearSeparator=" "
                                        value={this.state.slotDates[index]}
                                        weekStartDayIndex={1}
                                        showOtherDays
                                        fixMainPosition
                                        calendarPosition="bottom-end"
                                        offsetX={120}
                                        onChange={
                                          (dates: any) => {
                                            this.setDates(
                                              dates,
                                              (dates: string[]) =>
                                                setFieldValue(dateProp, dates)
                                            );
                                            this.setSlotDates(dates, index);
                                          }
                                        }
                                      >
                                        <Box
                                          borderTop="1px solid #E2E8F0"
                                          p={2}
                                        >
                                          <ButtonComponent>
                                            <Button
                                              data-test-id="save-date-btn"
                                              variant="contained"
                                              color="primary"
                                              onClick={
                                                this.calendarSlotRef[index]
                                                  .current?.closeCalendar
                                              }
                                              className="regButton"
                                              style={{
                                                ...styles.formButton,
                                                width: "100%",
                                              }}
                                            >
                                              Save
                                            </Button>
                                          </ButtonComponent>
                                        </Box>
                                      </DatePicker>
                                    </Box>
                                    <Typography className="txtErrorMsg">
                                      {isDateError && dateError}
                                    </Typography>
                                  </Box>
                                  {/* Duration */}
                                  <Box minWidth="48%">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        paddingTop: "15px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <InputLabel className="testLabel">
                                        Duration
                                      </InputLabel>
                                      <span>&nbsp;</span>
                                      <InputLabel className="testStar">
                                        *
                                      </InputLabel>
                                    </Box>
                                    <Autocomplete
                                      data-test-id="cat-duration-input"
                                      fullWidth
                                      placeholder="Select duration"
                                      options={
                                        this.state.services[index]?.attributes
                                          .catalogue_slots || [
                                          { id: 3, duration: "30 min" },
                                        ]
                                      }
                                      getOptionLabel={(option) =>
                                        option.duration
                                      }
                                      getOptionSelected={(option, value) =>
                                        option.id === value.id
                                      }
                                      value={categorySlot.slotData}
                                      className="inputeStyle"
                                      autoComplete
                                      autoHighlight
                                      disableClearable
                                      disablePortal
                                      onChange={(_, newValue) => {
                                        const selectedAvailability = (this.state.services[index]?.attributes || {}).availabilities?.find(item => (
                                          item.duration.duration === newValue.duration
                                        ));
                                        const timeStrings = this.generateTimeSlots(
                                          newValue.duration,
                                          index,
                                          selectedAvailability?.timeslots
                                        );
                                        setFieldValue(slotDataProp, {
                                          id: newValue.id,
                                          duration: newValue.duration,
                                        });
                                        // Reset selected slots
                                        let indexes: number[] = [];
                                        const slotIndexes = this.state.selectedSlotIndexes.find(slots => slots.id === newValue.id)?.indexes;
                                        selectedAvailability?.timeslots.forEach((timeSlot: string) => {
                                          indexes = this.pushOrRemoveIndex(
                                            indexes,
                                            timeStrings.indexOf(timeSlot)
                                          );
                                          const resIndexes = slotIndexes?.length ? slotIndexes : indexes;
                                          this.setSelectedSlotIndexes(resIndexes, newValue.id);
                                          setFieldValue(slotProp, resIndexes);
                                        });
                                      }}
                                      onBlur={handleBlur(slotDataProp)}
                                      popupIcon={<ExpandMoreIcon />}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          placeholder="Select duration"
                                          error={isDurationError}
                                          InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            style:
                                              this.getAutocompleteStyle(
                                                isDurationError
                                              ),
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <img
                                                  width={20}
                                                  height={20}
                                                  src={imgPendingClockGrey}
                                                  alt=""
                                                />
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    <Typography className="txtErrorMsg">
                                      {this.isAndConditionRendering(
                                        isDurationError,
                                        durationError
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                                {/* Time slots */}
                                {isDurationSelected && (
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        paddingTop: "15px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <InputLabel className="testLabel">
                                        Available Slots
                                      </InputLabel>
                                      <span>&nbsp;</span>
                                      <InputLabel className="testStar">
                                        *
                                      </InputLabel>
                                    </Box>
                                    <Box
                                      display="flex"
                                      gridGap={16}
                                      flexWrap="wrap"
                                    >
                                      {this.state.timeSlots[index].map(
                                        (slot, slotIndex) => (
                                          <Chip
                                            data-test-id={`chip-${slotIndex}`}
                                            label={slot}
                                            variant="outlined"
                                            key={slot}
                                            style={{
                                              height: "50px",
                                              borderRadius: "8px",
                                              border: "1px solid #398378",
                                              fontSize: "16px",
                                              cursor: "pointer",
                                              minWidth: "172px",
                                              ...this.getHighlightSlotStyle(
                                                slotIndex,
                                                values.categorySlots[index].slotData.id,
                                              ),
                                            }}
                                            onClick={() => {
                                              // Set or remove slots
                                              let availIndex = 0;
                                              const copyAvailabilities = values.categorySlots[index].availabilities;
                                              const copySlots = copyAvailabilities.find((availability, avIndex) => {
                                                availIndex = avIndex;
                                                return availability.slotId === values.categorySlots[index].slotData.id
                                              })?.timeSlots || [];
                                              const resultSlots = this.pushOrRemoveIndex(copySlots, slot);
                                              (copyAvailabilities as any)[availIndex].timeSlots = resultSlots;

                                              setFieldValue(
                                                availabilitiesProp,
                                                copyAvailabilities
                                              );

                                              const copyIndexes =
                                                (this.state.selectedSlotIndexes.find((slots) => slots.id === values.categorySlots[index].slotData.id))?.indexes || [];
                                              this.setSelectedSlotIndexes(
                                                this.pushOrRemoveIndex(
                                                  copyIndexes,
                                                  slotIndex
                                                ),
                                                values.categorySlots[index].slotData.id
                                              );
                                            }}
                                          />
                                        )
                                      )}
                                    </Box>
                                    <Typography className="txtErrorMsg">
                                      {isSlotsError && availabilitiesError}
                                    </Typography>
                                    <Button
                                      data-test-id="add-slot-btn"
                                      variant="contained"
                                      color="primary"
                                      onClick={(e) => {
                                        this.handleOpenDurationPopOver(e, index)
                                      }}
                                      className="regButton"
                                      style={{
                                        background: "#F1F5F9",
                                        color: "#047854",
                                        textTransform: "none",
                                        marginTop: "20px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <AddCircleOutlineIcon
                                        className="AddIconStyle"
                                        style={{
                                          color: "#398378",
                                          maxHeight: "22px",
                                        }}
                                      />
                                      <Typography
                                        style={{
                                          marginLeft: "4px",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Add New
                                      </Typography>
                                    </Button>
                                    <Popover
                                      open={!!this.state.anchorDuration}
                                      onClose={this.handleCloseDurationPopOver}
                                      anchorEl={this.state.anchorDuration}
                                      transformOrigin={{
                                        vertical: -35,
                                        horizontal: "left",
                                      }}
                                      PaperProps={{
                                        style: {
                                          paddingTop: "32px",
                                          border: "1px solid #FFFFFF",
                                          borderBottomRightRadius: "24px",
                                          boxShadow:
                                            "2px 3px 10px 1px rgba(0,0,0,0.35)",
                                        },
                                      }}
                                    >
                                      <Box
                                        mt={2}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        style={{ gap: 32 }}
                                      >
                                        <Box
                                          data-test-id="duration-time-from"
                                          style={{
                                            cursor: "pointer",
                                            color: this.getTimeColor({
                                              isFromTime:
                                                this.state.isDurationFrom,
                                            }),
                                          }}
                                          onClick={() =>
                                            this.setIsDurationFrom(true)
                                          }
                                        >
                                          <span
                                            style={{
                                              fontFamily: "Ubuntu",
                                              fontSize: 30,
                                            }}
                                          >
                                            {durationFrom.hours}:
                                            {durationFrom.minutes}
                                          </span>
                                          <Box style={styles.dayPartWrapper}>
                                            <span
                                              style={{
                                                ...styles.dayPart,
                                                color: this.setAmPmColor(
                                                  "am",
                                                  true
                                                ),
                                              }}
                                              onClick={() =>
                                                this.setTimeMarker("am", true)
                                              }
                                            >
                                              AM
                                            </span>
                                            <span
                                              style={{
                                                ...styles.dayPart,
                                                color: this.setAmPmColor(
                                                  "pm",
                                                  true
                                                ),
                                              }}
                                              onClick={() =>
                                                this.setTimeMarker("pm", true)
                                              }
                                            >
                                              PM
                                            </span>
                                          </Box>
                                        </Box>
                                        <img src={timeRangeArrow} />
                                        <Box
                                          data-test-id="duration-time-to"
                                          style={{
                                            cursor: "pointer",
                                            color: this.getTimeColor({
                                              isFromTime:
                                                !this.state.isDurationFrom,
                                            }),
                                          }}
                                          onClick={() =>
                                            this.setIsDurationFrom(false)
                                          }
                                        >
                                          <span
                                            style={{
                                              fontFamily: "Ubuntu",
                                              fontSize: 30,
                                            }}
                                          >
                                            {durationTo.hours}:
                                            {durationTo.minutes}
                                          </span>
                                          <Box style={styles.dayPartWrapper}>
                                            <span
                                              style={{
                                                ...styles.dayPart,
                                                color: this.setAmPmColor(
                                                  "am",
                                                  false
                                                ),
                                              }}
                                              onClick={() =>
                                                this.setTimeMarker("am", false)
                                              }
                                            >
                                              AM
                                            </span>
                                            <span
                                              style={{
                                                ...styles.dayPart,
                                                color: this.setAmPmColor(
                                                  "pm",
                                                  false
                                                ),
                                              }}
                                              onClick={() =>
                                                this.setTimeMarker("pm", false)
                                              }
                                            >
                                              PM
                                            </span>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                      >
                                        <Box
                                          position={"relative"}
                                          display={"flex"}
                                          overflow={"hidden"}
                                          height={"142px"}
                                          alignItems="center"
                                          justifyContent={"center"}
                                        >
                                          <div
                                            className="duration-picker-container"
                                            style={{ position: "relative" }}
                                          >
                                            <DurationPicker
                                              data-test-id="duration-picker"
                                              maxHours={12}
                                              onChange={this.onDurationChange}
                                            />
                                          </div>
                                        </Box>
                                      </Box>
                                      <Box py={2} px={4}>
                                        <ButtonComponent>
                                          <Button
                                            data-test-id="save-date-btn"
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleTimeSlots}
                                            className="regButton"
                                            style={{
                                              ...styles.formButton,
                                              width: "100%",
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </ButtonComponent>
                                      </Box>
                                    </Popover>
                                  </Box>
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                      <Box className="locationViewStyle" mt={2}>
                        <ButtonComponent>
                          <Button
                            data-test-id="back-btn"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.handleNavigationToBasicInformation(
                                "SpaUserProfile"
                              )
                            }
                            className="regButton"
                            style={{
                              background: "var(--Primary, #D9EEEB)",
                              color: "#398378",
                              ...styles.formButton,
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            data-test-id="submit-btn"
                            variant="contained"
                            color="primary"
                            onClick={submitForm}
                            className="regButton"
                            style={styles.formButton}
                          >
                            {this.state.loading ? (
                              <CircularProgress style={{ color: "#FFFFFF" }} />
                            ) : (
                              "Done"
                            )}
                          </Button>
                        </ButtonComponent>
                      </Box>
                    </Box>
                  </Box>
                </RegistrationFormView>
              );
            }}
          </Formik>
        </Box>
        <AppFooter navigation={this.props.navigation} />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
