import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';

export default class LandingPageAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationSubCategoriesMessage),
      getName(MessageEnum.NavigationVenueMessage),
      getName(MessageEnum.NavigationReviewsMessage)
    ]);
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      from.getData(getName(MessageEnum.NavigationScreenNameMessage))
    );
    if (from.id === getName(MessageEnum.NavigationSubCategoriesMessage)) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), 'SubCategories');
    }
    if (from.id === getName(MessageEnum.NavigationVenueMessage)) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), 'LandingPageVenue');
    }
    if (from.id === getName(MessageEnum.NavigationReviewsMessage)) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), 'Reviews');
    }
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );


    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
