import React from "react";
// Customizable Area Start
import { profileCover } from "./assets"
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import Coupons from "../../ordermanagement1/src/Coupons.web";
// Customizable Area End


class CustomerCoupons extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const customStyles = {
            background: `linear-gradient(
                rgba(0, 0, 0, 0.2), 
                rgba(0, 0, 0, 0.5)
              ), url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
        };

        return (
            <Coupons
                customStyles={customStyles}
                heading="Coupons"
                breadcrumbs={['Home', 'My Profile', 'Coupons']}
                navigation={this.props.navigation}
                fromCustomerProfile={true}
                id={""} />
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
export default CustomerCoupons;
// Customizable Area End


