import React from 'react'
import {
  Box,
  Typography,
} from "@material-ui/core";
import { imgShevronRight } from './assets';

export function BreadCrumbs({
  items,
  targetColor,
  fontSize,
  color,
  navigationMap = {},
  navigate = () => {}
}: {
  items: string[],
  targetColor?: string,
  fontSize?: string,
  color?: string,
  navigationMap?: { [key: string]: string },
  navigate?: (route: string) => void
}) {
  const itemsColor = color || '#94A3B8';

  const handleBreadcrumbClick = (item: string) => {
    if (navigationMap[item]) {
      navigate(navigationMap[item]);
    }
  };

  return (
    <Box display='flex'>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        return (
          <Box key={`${item}_${index}`} display='flex'>
            <Typography
             data-testid={`breadcrumb-${item}`}
              style={{
                fontFamily: 'Ubuntu',
                fontSize: fontSize || '16px',
                lineHeight: '14px',
                cursor: 'pointer',
                color: isLastItem ? (targetColor || itemsColor) : itemsColor,
                ...(isLastItem && { fontWeight: 700 })
              }}
              onClick={() => handleBreadcrumbClick(item)}
            >
              {item}
            </Typography>
            {!isLastItem && <img src={imgShevronRight} alt='shevron' style={{ padding: '0 8px' }} />}
          </Box>)
      })}

    </Box>)
}
