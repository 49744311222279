Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.subscriptionApiContentType = "application/json";
exports.subscriptionApiMethod = "GET";
exports.updateProfileApiMethod = "POST";
exports.currentSubscriptionApiUrl = "account_block/current_plan";
exports.subscriptionPlansApiUrl = "account_block/plans_list";
exports.updateProfileApiEndPoint = 'account_block/update_profile';
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling";
exports.labelBodyText = "subscriptionbilling Body";
exports.emptyCard = "Please enter card number";
exports.emptyName = "Please enter card holder";
exports.emptyCVV = "Please enter CVV";
exports.typeErrorCVV = "CVV should contain only numbers";
exports.numberErrorCVV = "CVV should contain only 3 numbers";
exports.emptyExpiry = "Please enter expiration date";
exports.invalidExpiry = "Please enter valid expiration date";
exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End