import { Box, Typography, styled } from '@material-ui/core';

export const TypographyView = styled(Typography)({
    '& *': {
        fontFamily: 'Ubuntu',
        fontWeight: 400,
        boxSizing: 'border-box'
    },
    '& .venue-carousel': {
        position: 'relative'
    },
    '& .carousel__dot': {
        borderRadius: '50%',
        border: 'none',
        padding: 0
    },
    '& .carousel__dot--selected': {
        backgroundColor: '#398378'
    },
    '& .MuiButton-root': {
        textTransform: 'none'
    },
    '& .MuiButton-label': {
        fontWeight: 700
    },
    '& .venue-service-carousel button': {
        border: 'none',
        background: 'none'
    },
    '& .venue-service-carousel button[disabled] div': {
        background: '#CBD5E1'
    },
    '& .venue-service-carousel button div': {
        background: '#398378'
    },
    '& .venue-service-carousel': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '& .buttons-carosel-tray': {
        width: '100%'
    },
    '& .agreeButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #398378)',
        fontFamily: 'Ubuntu',
        textTransform: 'none',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    },
    '& .declineButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #D9EEEB)',
        color: '#398378',
        fontFamily: 'Ubuntu',
        textTransform: 'none',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    },
    '& .MuiBox-root-19': {
        color: '#1B5950 !important'
    },
    '& .test-label': {
        color: 'initial',
        fontWeight: 700
    },
    '& .test-star': {
        color: '#DC2626',
        fontWeight: 700,
        marginLeft: '4px'
    },
    '& .txtErrorMsg': {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Ubuntu',
        paddingTop: '5px'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #398378'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
    },
    '& .MuiCheckbox-root': {
        padding: 0,
        marginRight: '8px'
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
        color: '#94A3B8'
    },
    '& .editButtonServices': {
        width: 96,
        height: 34,
        padding: '6px 10px',
        fontSize: '14px',
        color: '#000000',
        borderRadius: '8px',
        background: '#D9EEEB',
        fontWeight: 400,
        marginLeft: 'auto',
        '& .MuiButton-label': {
            fontWeight: 400
        }
    },
    '& input[type="file"]::file-selector-button': {
        cursor: 'pointer'
    },
    '& .sametimeAllDay': {
        width: 20,
        height: 20,
        borderRadius: 6,
        color: '#398378'
    }
});

export const RegistrationFormView = styled(Box)({
    borderRadius: '8px 8px 32px 8px',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    '& .MuiTypography-body1': {
        fontFamily: 'Ubuntu',
    },
    '& .txtErrorMsg': {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Ubuntu',
        paddingTop: '5px'
    },
    '& .title': {
        color: `var(--Basic-Black, #000)`,
        fontFamily: 'Ubuntu',
        fontSize: '24px',
        fontWeight: 700,
    },
    '& .testStar': {
        color: 'red',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: 700
    },
    '& .formHeader': {
        'display': 'flex',
        padding: '27px 172px 26px 172px',
        borderRadius: '24px 24px 0px 0px',
        background: 'var(--Primary, #398378)'
    },
    '& .logo': {
        width: '176px',
        height: '72px'
    },
    '& .MuiContainer-maxWidthLg': {
        width: '100%',
        "@media only screen and (min-width: 1280px)": {
            minWidth: '100%'
        }
    },
    '& .inputeStyle': {
        borderRadius: '8px',
        border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
        background: 'var(--Basic-White, #FFF)'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #398378 !important'
    },
    '& .MuiMenu-list': {
        maxHeight: '50vh',
    },
    '& .button': {
        background: '#398378',
        padding: '16px',
        textTransform: 'none',
        width: '525px',
        color: '#fffff'
    },
    '& .spaOwnerBtn': {
        background: '#E8F6F4',
        textTransform: 'none',
        width: '525px',
        color: '#398378',
        borderLeft: '4px solid #398378 !important',
        borderRadius: '8px',
        marginTop: '10px'
    },
    '& .button .MuiButton-label': {
        color: 'white !important'
    },
    '& .spaOwnerBtn .MuiButton-label': {
        color: '398378 !important'
    },
    '& .txtRemember': {
        color: '#334155'
    },
    '& .MuiBox-root-19': {
        color: '#1B5950 !important'
    },
    '& .MuiBox-root-14': {
        color: '#334155 !important'
    },
    '& .PrivateSwitchBase-root-18': {
        paddingLeft: '0px'
    },
    '& .forgotPwdText': {
        fontSize: "14px",
        color: "#1B5950",
        fontWeight: "400",
        marginTop: "5px",
    },
    '& .mainView': {
        padding: '0 32px 16px',
        flexDirection: 'column',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
        borderBottom: '1px solid #E2E8F0'
    },
    '& .testLabel': {
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: 700,
        fontSize: '16px',
        color: '#334155',
        fontFamily: 'Ubuntu'
    },
    '& .remember': {
        fontSize: "14px",
        color: "#334155",
        fontWeight: "400",
        marginTop: "5px",
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    '& .accountText': {
        fontSize: '16px',
        color: '#0F172A',
        fontWeight: 400,
        fontFamily: 'Ubuntu'

    },
    '& .signUp': {
        fontSize: '16px',
        color: '#0F172A',
        fontWeight: 'bold',
        fontFamily: 'Ubuntu',
        paddingLeft: '5px'

    },
    '& .moreIcon': {
        color: '#fff',
        fontSize: '16px',
    },
    '& .AddIcon': {
        color: '#F59E0B',
        fontSize: '16px',
    },
    '& .avtarImg': {
        width: '100px',
        height: '100px'
    },
    '& .pencileIcon': {
        width: '32px',
        height: '32px',
        position: 'absolute',
        marginLeft: '75px'
    },
    '& .AddIconStyle': {
        color: '#398378',
        fontSize: '16px',
    },
    '& .spaOwner': {
        width: ' 520px',
        textAlign: 'center',
        textTransform: 'none',
        padding: '16px',
    },
    '& .arrow': {
        color: '#398378',
        fontSize: '16px',
        paddingRight: '20px'
    },
    '& .MuiSnackbarContent-root': {
        background: '#FEE2E2',
        color: '#DC2626',
        borderLeft: '4px solid #DC2626',
        borderRadius: '4px',
        width: '100%',
    },
    '& .MuiSnackbar-anchorOriginBottomCenter': {
        transform: 'translateX(0%)',
        position: 'inherit',
        marginTop: '20px'
    },
    '& .PrivateSwitchBase-root-25': {
        paddingLeft: '0px'
    },
    '& .checkedIcon': {
        fontSize: '12px',
        color: '#34D399'
    },
    '& .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded': {
        top: '140px !important',
        left: '870px !important'
    },
    '& .businessLicenseView': {
        border: '2px',
        borderColor: '#CBD5E1',
        borderStyle: 'dashed',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '16px',
        padding: '6px 20px',
        flexDirection: 'column', display: 'flex',
        marginTop: '8px'

    },
    '& .uploadImg': {
        width: '36px',
        alignSelf: 'center'
    },
    '& .webLink': { display: 'flex', paddingTop: '15px', flexDirection: 'row', },
    '& .locationView': {
        display: 'flex', flexDirection: 'row',
        alignItems: 'center', justifyContent: 'center',
        borderRadius: '8px', backgroundColor: '#E8F6F4', alignSelf: 'flex-end',
        padding: '5px',
        marginTop: '15px',
        cursor: 'pointer'
    },
    '& .locationView[disabled]': {
        backgroundColor: '#d7ded6'
    },
    '& .locationView[disabled] p': {
        color: '#FFFFFF'
    },
    '& .locationView[disabled] svg': {
        color: '#FFFFFF'
    },
    '& .locationViewStyle': { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' },
    '& .fileView': { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
    '& .documentView': {
        display: 'flex', flexDirection: 'row', alignItems: 'center',
        justifyContent: 'space-between', backgroundColor: 'var(--Primary, #398378)', padding: '16px'
    },
    '& .uploadedDocument': {
        flexDirection: 'row',
        display: 'flex',
        borderRadius: '6px',
        border: '1px solid #CBD5E1',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        padding: '10px',
        width: '427px'
    },
    '& .businessLicense': {
        fontSize: '16px',
        fontWeight: '700',
        fontFamily: 'Ubuntu',
        color: '#334155',
        alignSelf: 'center',
        textAlign: 'center'
    },
    '& .businessLicenseDesc': {
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Ubuntu',
        color: '#334155',
        alignSelf: 'center'
    },
    '& .browseFile': {
        fontSize: '14px',
        fontWeight: '700',
        fontFamily: 'Ubuntu',
        color: '#F59E0B',
        marginLeft: '5px'
    },
    '& .addOther': {
        fontSize: '14px',
        fontWeight: '700',
        fontFamily: 'Ubuntu',
        color: '#398378',
        paddingLeft: '5px'
    },
    '& .licenseTxt': {
        fontSize: '12px',
        fontFamily: 'Ubuntu',
        fontWeight: '400',
        color: '#0F172A',
    },
    '& .fileSize': {
        fontSize: '12px',
        fontFamily: 'Ubuntu',
        fontWeight: '400',
        color: '#64748B'
    },
    '& .removeItem': {
        display: 'flex',
        backgroundColor: '#E8F6F4',
        width: '35px', height: '35px',
        borderRadius: '6px', border: '1px solid #E8F6F4',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .MuiAutocomplete-option:hover': {
        backgroundColor: '#D9EEEB'
    },
    '& .MuiAutocomplete-endAdornment': {
        right: '8px'
    },
    '& .MuiAutocomplete-popper': {
        backgroundColor: '#FFFFFF',
    },
    '& .MuiPopover-paper': {
        backgroundColor: '#FFFFFF'
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
        color: '#398378',
        fontWeight: 700,
        background: '#E8F6F4'
    },
    '& .MuiStepIcon-root': {
        color: '#94A3B8'
    },
    '& .MuiStepIcon-active': {
        color: '#000000'
    },
    '& .MuiStepConnector-lineHorizontal': {
        borderTopWidth: '2px',
        borderColor: '#94A3B8',
        margin: 'auto'
    },
    '& .MuiStepIcon-completed': {
        color: '#34D399'
    },
    '& .MuiStepConnector-root': {
        margin: '0 15px'
    },
    '& .MuiStepConnector-active span': {
        borderColor: '#398378'
    },
    '& .MuiStep-horizontal': {
        padding: 0
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '5px'
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#D9EEEB',
            borderRadius: '4px'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#398378',
            outline: '1px solid slategrey',
            borderRadius: '4px'
        }
    }
});

export const styles = {
    SafeAreaView: {
        backgroundColor: '#e8f6f4ff',
    },
    dotsOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '70%'
    },
    actionButton: {
        borderRadius: '8px',
        fontSize: '16px',
        background: 'var(--Primary, #398378)',
        fontFamily: 'Ubuntu',
        textTransform: 'capitalize',
        padding: '8px 24px',
        margin: '16px 0'
    },
    paymentItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        width: '140px',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box'
    },
    paymentImage: {
        objectFit: 'contain',
        maxHeight: '100%'
    },
    inputBlock: {
        width: '48%'
    },
    inputTitle: {
        display: 'flex',
        flexDirection: 'row',
        padding: '16px 0 8px'
    },
    modeButton: {
        heigth: '56px',
        minWidth: '48%',
        border: '1px solid #7EC4B9',
        boxShadow: 'none'
    },
    shevron: {
        width: '24px',
        height: '30px',
    },
    boldSmall: {
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        fontWeight: 700,
        color: '#000000'
    },
    regularMiddle: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
        color: '#000000'
    },
    serviceBlock: {
        marginTop: '20px',
        backgroundColor: '#ffffff',
        padding: '16px'
    },
    carouselButton: {
        borderRadius: '100px',
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)'
    },
    uploadBox: {
        padding: "8px 4px 8px 8px",
        gap: "16px",
        borderRadius: "8px",
        border: "1px",
        background: "#FFFFFF",
        width: '208px'
    },
    lightBorder: {
        border: '1px solid #CBD5E1'
    },
    blockButton: {
        display: 'block',
        margin: '8px 0 0 auto'
    },
    clickBlock: {
        p: '7px 24px 6px 24px',
        height: '56px',
        borderRadius: '30px',
        border: "1px solid #CBD5E1",
        bgcolor: '#ffffff',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        mb: '16px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    scheduleArrow: {
        background: 'none',
        border: 'none',
        padding: 0
    },
    popperStyle: {
        bgcolor: 'background.paper',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
        marginTop: '2px'
    },
    formButton: {
        height: '56px',
        width: 'auto',
        minWidth: '100px',
        padding: '16px'
    }
};

export const ButtonComponent = styled(Box)({
    display: "flex",
    gridGap: '32px',
    flexDirection: "row",
    alignItems: "center !important",
    justifyContent: 'space-between',
    padding: "0 0 30px",
    '& .regButton': {
      display: 'flex',
      width: '150px',
      height: '65px',
      borderRadius: '8px',
      fontSize: '16px',
      background: 'var(--Primary, #398378)',
      fontFamily: 'Ubuntu',
      textTransform: 'none',
      textAlign: 'center',
      padding: 0
    }
  });
