import React, { ReactElement } from 'react';
export const configJSON = require("./config");

export function validateFileInput(this: React.Component, files: FileList, stateSetter: (filesArray: File[]) => void, titleText: string) {
    const isCert = titleText === configJSON.txtCertification;
    const isLicense = titleText === configJSON.txtBusinessLicense;
    const isFile = titleText === configJSON.txtAddOtherFiles;

    this.setState({ fileError: '' });
    const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png'
    ];
    const filesArray = Array.from(files);
    const isError = filesArray.some((file) => !allowedTypes.includes(file.type));

    if (isError) {
        isCert && this.setState({ certificateError: configJSON.invalidFileErrorMsg });
        isLicense && this.setState({ businessLicenseError: configJSON.invalidFileErrorMsg });
        isFile && this.setState({ fileError: configJSON.invalidFileErrorMsg });
    } else {
        stateSetter(filesArray);
    }
}

export function createUploaders(this: React.Component<{}, {
    uploadersNumber?: number,
    certificateError?: string,
    businessLicenseError?: string,
    fileError?: string,
    businessLicenseImage?: File[],
    certificateImage?: File[],
    otherImages: File[][] | File[],
    addDisabled?: boolean,
    anchorElLicence?: any[],
    anchorElCertificate?: any[]
}>, callback: (number: number, resultFileError: string, resultSetter: any) => ReactElement) {
    const stateUploaders = this.state.uploadersNumber;
    const uploadersNumber = stateUploaders || 3;
    return new Array(uploadersNumber).fill(null).map((number, index) => {
        const isBusinessLicenseBl = !index;
        const isCertificationBl = index === 1;
        const isFileError = index > 1;

        const certError = isCertificationBl && this.state.certificateError;
        const licenseError = isBusinessLicenseBl && this.state.businessLicenseError;
        const fileError = isFileError && this.state.fileError;
        const resultFileError = certError || licenseError || fileError || '';

        const businessObj = !index && {
            setter: ((file: File[]) => this.setState({ businessLicenseImage: file, businessLicenseError: '' })),
            popoverAnchor: 'anchorElLicence',
            title: (this as any).licenseTxt || configJSON.txtBusinessLicense,
            uploadedFile: this.state.businessLicenseImage
        };
        const certObj = index === 1 && {
            setter: ((file: File[]) => this.setState({ certificateImage: file, certificateError: '' })),
            popoverAnchor: 'anchorElCertificate',
            title: (this as any).certTxt || configJSON.txtCertification,
            uploadedFile: this.state.certificateImage
        };
        const othersObj = {
            setter: ((file: File[]) => this.setState({ otherImages: file })),
            popoverAnchor: 'anchorElOtherFiles',
            title: (this as any).otherFilesTxt || configJSON.txtAddOtherFiles,
            uploadedFile: this.state.otherImages,
            ...(stateUploaders && {
                setter: (file: File[]) => {
                    const otherImagesCopy = this.state.otherImages;
                    otherImagesCopy[index] = file;

                    this.setState({
                        otherImages: otherImagesCopy,
                        addDisabled: !this.state.otherImages[uploadersNumber - 1],
                        uploadersNumber: uploadersNumber - Number(!file.length)
                    })
                },
                uploadedFile: this.state.otherImages[index]
            })
        };

        const resultSetter = certObj || businessObj || othersObj;

        return callback(number, resultFileError, resultSetter)
    })
}
