import { Box, Button, InputLabel, Modal, Popover, TextField, Typography } from '@material-ui/core';
import React from 'react';
import dayjs from "dayjs";
import { PriceInput, TypographyView, styles } from '../styledFont';
import { Formik } from 'formik';
import Ordermanagement1Controller, { Props, initRescheduleFormValues } from '../Ordermanagement1Controller';
import * as Yup from "yup";
import { RegistrationFormView } from '../regView';
import CloseIcon from '@material-ui/icons/Close';
import { arrowDown, imgDollarSign, imgPendingClockGreen } from '../assets';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { calculateSlots, generateDatesRange } from '../../../../components/src/datesRangeHelper';
import moment from 'moment';

export default class CustomerRescheduleModal extends Ordermanagement1Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.setDatesRange = this.setDatesRange.bind(this);
        this.renderDatarange = this.renderDatarange.bind(this);
        // Customizable Area End
    }


    renderDatarange(
        currentDate: string,
        index: number,
        scheduleSlots: {
            time: string,
            duration: string,
            isAvailable: boolean
        }[],
        setFieldValue: (arg: string, val: string) => void,
        extractedBookedSlots: {
            booking_date: string,
            time_range: string
        }[],
    ) {


        return (
            <Slide index={index} key={`${currentDate}_${index}`}>
                <Box
                    display='flex'
                    flexWrap='wrap'
                    gridGap='16px'
                    justifyContent='flex-start'
                >
                    {scheduleSlots.map((slot) => {

                        const isBooked = extractedBookedSlots?.some((bookedSlot: { booking_date: string; time_range: string }) => {
                            const normalizeTime = (timeRange: string) => {
                                return timeRange
                                    .split('-')
                                    .map(time => time.trim().replace(/^0+/, ''))
                                    .join('-');
                            };

                            const slotTime = normalizeTime(slot.time.toLowerCase());
                            const bookedSlotTime = normalizeTime(bookedSlot.time_range.toLowerCase());

                            const parsedDate = new Date(currentDate);
                            const formattedCurrentDate = parsedDate.toISOString().split('T')[0];

                            return (
                                bookedSlot.booking_date === formattedCurrentDate &&
                                bookedSlotTime === slotTime
                            );
                        });

                        const isDisabled = isBooked || !slot.isAvailable;
                        const backgroundColor = isDisabled ? '#E2E8F0' : '#FFFFFF';
                        const textColor = isDisabled ? '#94A3B8' : '#64748B';

                        return (
                            <Button
                                key={slot.time}
                                data-test-id='slot-item-btn'
                                style={{
                                    display: 'block',
                                    padding: '4px 8px',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '6px',
                                    color: textColor,
                                    width: '30%',
                                    backgroundColor: backgroundColor,
                                    cursor: isDisabled ? 'not-allowed' : 'pointer'
                                }}
                                disabled={isDisabled}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    if (isDisabled) {
                                        return;
                                    }
                                    const buttonElem = event.currentTarget;

                                    if (buttonElem.getAttribute('data-clicked')) {
                                        buttonElem.style.backgroundColor = '#FFFFFF';
                                        (buttonElem as any).firstChild.style.color = '#64748B';
                                        buttonElem.removeAttribute('data-clicked');
                                    } else {
                                        buttonElem.setAttribute('data-clicked', 'true');
                                        buttonElem.style.backgroundColor = '#398378';
                                        (buttonElem as any).firstChild.style.color = '#FFFFFF';
                                    }

                                    setFieldValue('slot', slot.time);
                                    setFieldValue("date", currentDate);

                                    this.setState({
                                        anchorEl: null,
                                        currentSlotIndex: 0
                                    });
                                }}
                            >
                                <Typography
                                    className='slot-name'
                                    style={{
                                        fontSize: '12px',
                                        marginBottom: '4px',
                                    }}
                                >
                                    {slot.time}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '10px',
                                        color: '#94A3B8',
                                        textTransform: 'none'
                                    }}
                                >
                                    {slot.duration}
                                </Typography>
                            </Button>
                        );
                    })}
                </Box>
            </Slide>
        );
    }

    render() {
        const { userName } = this.props;
        const { isCustomer, bookingDetails } = this.state;

        const {
            id = undefined,
            start_time = '',
            end_time = '',
            booking_date = '',
            total_price = 0,
            personal_data: {
                service_name = '',
                slot: {
                    duration = '',
                    id: slotId = 0
                } = {},
                spa_detail: {
                    spa_timing = ''
                } = {}
            } = {}
        } = this.getEmptyObject(bookingDetails as any);


        const filteredAvailabilities = this.props.customerCatalogue?.availabilities.filter((availability) => {
            return availability.duration.duration === duration;
        });

        const bookedSlots = this.props.customerCatalogue?.booked_slots;
        const extractedBookedSlots = bookedSlots ? this.formatBookedSlots(bookedSlots) : [];

        const spaOpenTiming = this.formatSpaTiming(spa_timing);

        const thisYear = new Date().getFullYear()
        const datesRange = generateDatesRange(`${thisYear}-01-01T00:00:00.000Z`, `${thisYear + 1}-12-31T00:00:00.000Z`);
        const firstDate = datesRange[0];

        return <><Modal
            data-test-id='review-modal'
            open={Boolean(this.props.openCustomerRescheduleModal)}
            onClose={() => this.props.setCustomerRescheduleModal?.(false)}
        >
            <TypographyView>
                <Formik
                    data-testid="reschedule-form"
                    initialValues={this.props.modalData || initRescheduleFormValues}
                    validationSchema={Yup.object().shape({
                        name: Yup.string(),
                        serviceName: Yup.string(),
                        duration: Yup.string(),
                        slot: Yup.string(),
                        date: Yup.string(),
                        price: Yup.number()
                    })}
                    onSubmit={async (values) => {
                        const [start_time, end_time] = values.slot.split(" - ")
                        const data = {
                            start_time: moment(`${values.date} ${start_time}`).format('YYYY-MM-DD HH:mm:ss'),
                            end_time: moment(`${values.date} ${end_time}`).format('YYYY-MM-DD HH:mm:ss'),
                            booking_date: moment(values.date).format("YYYY-MM-DD"),
                            timezone: "UTC",
                            price: values.duration?.price,
                        }
                        await this.rescheduleBooking(data, this.props.id)
                        this.props.setOpenRescheduleModal?.(false)
                        this.props.setOpenSnack?.()
                    }}
                >
                    {({
                        handleChange,
                        errors,
                        handleBlur,
                        setFieldValue,
                        values,
                        submitForm
                    }) => {
                        const presentTime = values.slot;

                        const scheduleSlots = calculateSlots(
                            String(spaOpenTiming),
                            duration,
                            {
                                timeSlots: filteredAvailabilities?.flatMap(a => a.timeslots),
                                dates: datesRange,
                                currentDate: firstDate
                            }
                        );

                        if (!this.state.datesRange[this.state.currentSlotIndex]) {
                            const copyDatesRange: any = this.state.datesRange;
                            copyDatesRange[0] = this.renderDatarange(datesRange[0], 0, scheduleSlots, setFieldValue, extractedBookedSlots);
                            this.setDatesRange(copyDatesRange);
                        }

                        return (
                            <RegistrationFormView
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '45%',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '8px 8px 32px 8px',
                                    outline: 'none'
                                }}
                            >
                                <Box p={3} pl={5} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {this.props.modalContent?.title}
                                    </Typography>
                                    <CloseIcon
                                        data-test-id='review-close-btn'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.props.setOpenRescheduleModal?.(false)}
                                    />
                                </Box>
                                <Box px={5} pb={2} borderBottom='1px solid #E2E8F0'>
                                    <Box >
                                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                            <InputLabel className="testLabel">Name</InputLabel>
                                        </Box>
                                        <TextField
                                            disabled={isCustomer}
                                            value={userName}
                                            fullWidth={true}
                                            variant="outlined"
                                            placeholder="Enter customer name"
                                        />
                                    </Box>
                                    <Box>
                                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                            <InputLabel className="testLabel">Service Type</InputLabel>
                                        </Box>
                                        <TextField
                                            disabled={isCustomer}
                                            value={service_name}
                                            fullWidth={true}
                                            variant="outlined"
                                            placeholder="Enter customer name"
                                        />
                                    </Box>
                                    <Box width='100%'>
                                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                            <InputLabel className="testLabel">Duration</InputLabel>
                                        </Box>
                                        <TextField
                                            disabled={isCustomer}
                                            value={duration}
                                            fullWidth={true}
                                            variant="outlined"
                                            placeholder="Enter customer name"
                                        />
                                    </Box>
                                    <Box
                                        data-test-id='select-slot-block'
                                        mt={2}
                                        p='12px'
                                        borderRadius='30px'
                                        border='1px solid #E2E8F0'
                                        bgcolor='#ffffff'
                                        whiteSpace='nowrap'
                                        mb='14px'
                                        position='relative'
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) => {
                                            this.setState({ anchorEl: event.currentTarget })
                                            const copyDatesRange = this.state.datesRange;
                                            const resIndex = this.state.currentSlotIndex
                                            copyDatesRange[resIndex] = this.renderDatarange(datesRange[resIndex], resIndex, scheduleSlots, setFieldValue, extractedBookedSlots);
                                            this.setDatesRange(copyDatesRange);
                                        }
                                        }
                                    >
                                        <Box p={1} borderRadius='50%' bgcolor='#D1FAE5' display='inline-block' mr='14px'>
                                            <img src={imgPendingClockGreen} alt='map marker' />
                                        </Box>
                                        <Typography
                                            style={{
                                                ...styles.regularMiddle,
                                                display: 'inline-block',
                                                verticalAlign: 'text-bottom',
                                                color: '#94A3B8',
                                                ...(presentTime && {
                                                    color: '#334155',
                                                    fontWeight: 700
                                                })
                                            }}>
                                            {presentTime || 'Select slot'}
                                        </Typography>
                                        <img
                                            src={arrowDown}
                                            style={{
                                                position: 'absolute',
                                                right: '24px',
                                                top: '50%',
                                                transform: 'translateY(-50%)'
                                            }}
                                            alt='arrow'
                                        />
                                    </Box>

                                    <Popover
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={() => {
                                            this.setState({
                                                anchorEl: null,
                                                currentSlotIndex: 0
                                            });
                                        }}
                                        anchorEl={this.state.anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 21,
                                        }}
                                        style={styles.popperStyle}
                                        PaperProps={{
                                            style: { width: '460px' },
                                        }}
                                    >
                                        <Box p={3} >
                                            <CarouselProvider
                                                dragEnabled={false}
                                                naturalSlideWidth={410}
                                                naturalSlideHeight={264}
                                                totalSlides={datesRange.length}
                                                visibleSlides={1}
                                                isIntrinsicHeight
                                                className='venue-schedule-carousel'
                                            >
                                                <Box display='flex' gridGap='5px' alignItems='center'>
                                                    <Box>
                                                        <ButtonBack
                                                            onClick={
                                                                () => this.state.currentSlotIndex > 0 && this.setState({
                                                                    currentSlotIndex: this.state.currentSlotIndex - 1
                                                                })}
                                                            style={styles.scheduleArrow}
                                                        >
                                                            <KeyboardArrowLeft style={{ width: '18px' }} />
                                                        </ButtonBack>
                                                        <ButtonNext
                                                            date-test-it="new-button"
                                                            onClick={
                                                                () => {
                                                                    if (this.state.currentSlotIndex < datesRange.length) {
                                                                        this.setState({ currentSlotIndex: this.state.currentSlotIndex + 1 });
                                                                        const resIndex = this.state.currentSlotIndex + 1;

                                                                        // Set each next slide separatelly to prevent performance issue
                                                                        const copyDatesRange = this.state.datesRange;
                                                                        copyDatesRange[resIndex] = this.renderDatarange(datesRange[resIndex], resIndex, scheduleSlots, setFieldValue, extractedBookedSlots);
                                                                        this.setDatesRange(copyDatesRange);
                                                                    }
                                                                }
                                                            } style={styles.scheduleArrow} >
                                                            <KeyboardArrowRight style={{ width: '18px' }} />
                                                        </ButtonNext>
                                                    </Box>
                                                    <Typography
                                                        style={{
                                                            ...styles.boldSmall,
                                                            color: '#000000'
                                                        }}
                                                    >
                                                        {datesRange[this.state.currentSlotIndex]}
                                                    </Typography>
                                                </Box>

                                                <Slider
                                                    classNameTray='schedule-tray'
                                                    style={{
                                                        display: 'flex',
                                                        margin: '28px 0 14px'
                                                    }}>
                                                    {this.state.datesRange}
                                                </Slider>
                                            </CarouselProvider>
                                        </Box>
                                    </Popover>

                                    <Box
                                        mt={2}
                                        p='12px'
                                        borderRadius='30px'
                                        border='1px solid #E2E8F0'
                                        bgcolor='#ffffff'
                                        whiteSpace='nowrap'
                                        mb='14px'
                                        style={{
                                            cursor: 'pointer',
                                            background: "#F8FAFC"
                                        }}>
                                        <PriceInput
                                            name='price'
                                            value={total_price}
                                            disabled
                                            fullWidth={true}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="standard"
                                            data-test-id="txtInputSpaName"
                                            InputProps={{
                                                startAdornment: (
                                                    <Box p={1} borderRadius='50%' bgcolor='#FEF3C7' display='inline-block' mr='14px'>
                                                        <img src={imgDollarSign} alt='map marker' />
                                                    </Box>
                                                ),
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Box>
                                    <Typography className="txtErrorMsg">{(errors as any)?.name}</Typography>
                                </Box>
                                <Box p={3}>
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        width='100%'>
                                        <Button
                                            data-test-id="cancel-btn"
                                            variant="contained"
                                            type='submit'
                                            color="primary"
                                            className="declineButton"
                                            style={{
                                                marginLeft: 'auto',
                                                width: 'auto',
                                                padding: '6px 16px'
                                            }}
                                            onClick={submitForm}
                                        >
                                            Reschedule
                                        </Button>
                                    </Box>
                                </Box>

                            </RegistrationFormView>
                        )
                    }}
                </Formik>
            </TypographyView>
        </Modal>
        </>
    }
}
