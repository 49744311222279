import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';
import { calculateSlots, generateDatesRange } from '../../../components/src/datesRangeHelper';

// Customizable Area Start
import moment, { duration } from "moment";
import momentTz, { Moment } from 'moment-timezone';
import { getAndSaveCurrency } from '../../../components/src/helpers';

export type SubCategory = {
  subcategory: {
    id: number,
    name: string
  },
  slots: {
    id: 4,
    duration: string,
    price: string,
    start_time: string,
    end_time: string
  }[]
}

interface BookingSlot {
  start_time: string;
  end_time: string;
  booking_date: string;
  service_provider_id: number;
  catalogue_id: number;
  bx_block_categories_slot_id: number;
  status: string;
  customer_id: number;
}

interface Availabilities {
  timeslots: string[];
  dates: string[];
  duration: {
    duration: string;
  };
}

interface TimeslotData {
  id: string;
  name: string;
  availabilities: Availabilities[];
  booked_slots: BookingSlot[];
}

type UserProfile = {
  id: string;
  attributes: {
    full_name: string;
    open_timing: string;
    about: string;
    about_us: string;
    phone_number: number | null;
    email: string;
    profile_data: {
      attributes: {
        account_id: number,
        address: string,
        photo: string,
        bio: string,
      }
    }
    services: {
      subcategories_with_slots: SubCategory[]
    }[],
    reviews: {
      name: string;
      review: string;
      comment: string;
      created_at: string;
    }[];
    image_url: string,
    average_review: number,
    average_review_string: string,
    carousal_images: {
      id: number,
      url: string
    }[]
  }
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openCustomerRescheduleModal?: boolean;
  setCustomerRescheduleModal?: (arg: boolean) => void;
  currentDate?: string[];
  scheduleSlots?: Array<{
    time: string;
    duration: string;
    ordered: boolean;
    isAvailable: boolean;
  }>;
  availableSlots?: string[];
  newDate?: string;
  openTiming?: string | undefined;
  selectedTimeSlot?: { date: string; slot: { time: string; duration: string; ordered: boolean } } | null;
  onSelectTimeSlot?: (date: string, slot: { time: string; duration: string; ordered: boolean } | null) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  venueCarouselIndex: number;
  buttonIndex: number,
  anchorEl: HTMLButtonElement | null,
  isLeftClick: boolean,
  categories: any[],
  venuesData: any[],
  paginatedVenues: any[];
  totalVenues: number;
  imageLoading: boolean,
  userDetails: {
    id: string;
    attributes: {
      full_name: string;
      open_timing: string;
      about: string;
      about_us: string;
      phone_number: number | null;
      email: string;
      currency: string;
      profile_data: {
        attributes: {
          account_id: number,
          address: string,
          photo: string,
          bio: string
        }
      }
      services: {
        subcategories_with_slots: SubCategory[]
      }[],
      reviews: {
        name: string;
        review: string;
        comment: string;
        created_at: string;
      }[];
      image_url: string,
      average_review: number,
      average_review_string: string,
      carousal_images: {
        id: number,
        url: string
      }[]
    }
  } | null,
  pathParam: number,
  currentSlotIndex: number;
  currentPopperIndex: number;
  datesRange: any[][],
  landingPageData: {
    about_us: {
      description: string
    },
    products: {
      id: number
      name: string,
      image_url: string
    }[],
    coupons: {
      image_url: string
    }[]
  },
  reviewsList: {
    id: number;
    attributes: {
      average_review: number,
      average_review_string: string,
      rating: number,
      comment: string,
      created_at: string,
      review_photos: {
        id: string,
        url: string,
        media_type: string
      }[],
      account: {
        attributes: {
          first_name: string,
          full_name: string
        }
      }
    }
    name: string;
    comment: string;
    created_at: string
  }[];
  showCreateReviewModal: any;
  selectedTimeSlot: {
    date: string;
    slot: {
      time: string;
      duration: string;
      ordered: boolean;
    };
  } | null;
  selectedTimeSlotPrice: {
    date: string;
    time: string;
    start_time: string;
    end_time: string;
    duration: string;
    price: string;
    subcategoryName: string | null;
    slotId: number;
    accountId: number | undefined;
    catelogueId: string | undefined;
  } | null;
  selectedSubcategoryId: number | null;
  selectedCatalogue: TimeslotData | null;
  availableSlots: string[];
  slotAvailability: {
    isAvailable: boolean;
    isSelected: boolean;
    time: string;
    duration: string;
    ordered: boolean;
  }[];
  newSlotsAvailable: string[];
  catalogueChanged: boolean;
  reorderedDates: string[];
  currentDate: string;
  customerEmail: "",
  timeSlots: string[];
  prevIsAvailable: boolean | null;
  customerDetails: UserProfile | null;
  detectedTimeZone: string;
  openCustomerRescheduleModal: boolean;
  loading: boolean;
  currentPage: number,
  venuesPerPage: number,
  selectServiceName: string,
  isOpen: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currency: string;
  apiGetCategoriesCallId = '';
  apiGetUserDetailsCallId = '';
  apiGetCataloguesDetailsCallId = '';
  apiPostCustomerDetailsCallId = '';
  getSubCategoriesCallId = '';
  apiGetLandingPageDataCallId = '';
  apiGetUserReviewCallId = '';
  apiCreateUserReviewCallId = '';
  apiGetCustomerDetailsCallId = '';
  pathParam = this.props.navigation.getParam('navigationBarTitleText', 0);
  private formattedSlotsCache: { [key: string]: any[] } = {};

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.formattedSlotsCache = {};

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      venueCarouselIndex: 0,
      buttonIndex: 0,
      anchorEl: null,
      isLeftClick: false,
      categories: [],
      venuesData: [],
      paginatedVenues: [],
      totalVenues: 0,
      userDetails: null,
      customerDetails: null,
      pathParam: 0,
      currentSlotIndex: 0,
      currentPopperIndex: 0,
      datesRange: Array.from(new Array(50), () => []),
      imageLoading: false,
      landingPageData: {
        about_us: {
          description: ''
        },
        products: [],
        coupons: []
      },
      reviewsList: [],
      showCreateReviewModal: false,
      selectedTimeSlot: null,
      selectedTimeSlotPrice: null,
      selectedSubcategoryId: null,
      selectedCatalogue: null,
      availableSlots: [],
      slotAvailability: [],
      newSlotsAvailable: [],
      catalogueChanged: false,
      reorderedDates: [],
      currentDate: "",
      customerEmail: "",
      timeSlots: [],
      prevIsAvailable: null,
      detectedTimeZone: 'Asia/Kolkata',
      openCustomerRescheduleModal: false,
      loading: false,
      currentPage: 1,
      venuesPerPage: 9,
      selectServiceName: "",
      isOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);

    if (message.id === navigationPayloadMessage) {
      const currentNavData = message.getData(navigationPayloadMessage)
      currentNavData === 'showReviewPrompt' && this.setState({ showCreateReviewModal: true });
    }

    if (responseJson) {
      if (apiRequestCallId === this.apiGetCategoriesCallId) {
        this.setState({ categories: responseJson.data })
      }
      if (apiRequestCallId === this.getSubCategoriesCallId) {
        this.setState({
          venuesData: responseJson.data,
          imageLoading: true,
          loading: false,
          totalVenues: responseJson.data.length,
        },
          () => {
            this.updatePaginatedVenues();
          }
        )
      }
      if (apiRequestCallId === this.apiGetUserDetailsCallId) {
        this.currency = getAndSaveCurrency(responseJson);
        this.setState({ userDetails: responseJson.data });
      }
      if (apiRequestCallId === this.apiGetLandingPageDataCallId) {
        this.setState({ landingPageData: responseJson.data })
      }
      if (apiRequestCallId === this.apiGetUserReviewCallId) {
        this.setState({
          reviewsList: responseJson.data?.data?.reverse() || []
        })
      }
      if (apiRequestCallId === this.apiCreateUserReviewCallId) {
        this.getUserReviews();
      }
      if (apiRequestCallId === this.apiGetCataloguesDetailsCallId) {
        const { id, attributes } = responseJson.data;
        const { name, availabilities, booked_slots } = attributes;
        if (availabilities && availabilities.length > 0) {
          const processedAvailabilities = availabilities.map((availability: { timeslots: any; dates: any; duration: any }) => ({
            timeslots: availability.timeslots,
            dates: availability.dates,
            duration: availability.duration
          }));

          this.setState({
            selectedCatalogue: {
              id,
              name,
              booked_slots,
              availabilities: processedAvailabilities
            }
          }, () => {
            this.forceUpdate();
          });
        }
      }
      if (apiRequestCallId === this.apiPostCustomerDetailsCallId) {
        const bookingId = responseJson.data?.id;
        if (bookingId) {
          this.gotoBookingDetails(bookingId);
        }
      }
      if (apiRequestCallId === this.apiGetCustomerDetailsCallId) {
        this.setState({ customerDetails: responseJson.data })
      }
    }

    if (errorReponse) {
      responseJson ?
        this.parseApiCatchErrorResponse(responseJson.message) :
        this.showAlert(configJSON.commonErrorText, configJSON.commonErrorDescription);
    }

    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToCategoryVenues(id: number) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSubCategoriesMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);

    this.send(msg);

    window.scrollTo(0, 0);
  };

  goToVenue(id: string) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationVenueMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);

    this.send(msg);
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSpaRegistration() {
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpSPAInitMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { userRole: 'Spa' });

    this.send(message);
  }

  goToReviews() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationReviewsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.pathParam);

    this.send(msg);
  };

  async getUserDetails() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${this.pathParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getCustomerDetails() {
    const userID = await getStorageData('user_id');
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${userID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLandingPageData() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLandingPageDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSliderClick(event: React.MouseEvent<HTMLElement>, backBtnRef: HTMLElement, nextBtnRef: HTMLElement) {
    const isLeftClick = event.screenX < window.innerWidth / 2;
    const targetRef = isLeftClick ? backBtnRef : nextBtnRef;

    targetRef.click();

    const carouslImagesLength = this.state.userDetails?.attributes?.carousal_images.length;

    const currentIndex = this.state.venueCarouselIndex;
    const prevSlide = currentIndex > 0 && currentIndex - 1;
    const nextSlide = currentIndex + Number(currentIndex < Number(carouslImagesLength) - 1);

    this.setState({ venueCarouselIndex: Number(isLeftClick ? prevSlide : nextSlide) });
  };

  getSubCategoriesData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoriesCallId = requestMessage.messageId
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_dashboard4/get_category_spas?id=${this.pathParam}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPIMethod)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  calculateDotsDimensions() {
    const slidesNumber = this.state.userDetails?.attributes?.carousal_images?.length;
    const highSlideEdge = 6;
    const lowSlideEdge = 4;
    // Handle case if there is only one slide
    const multiplier = slidesNumber && slidesNumber > 1 ? (highSlideEdge - lowSlideEdge) / (slidesNumber - 1) : 0;

    return new Array(slidesNumber)
      .fill(highSlideEdge)
      .map((_, index) => highSlideEdge - Math.abs(this.state.venueCarouselIndex - index) * multiplier);
  }

  getResultVouchers() {
    return this.state.landingPageData.coupons.map((coupon) => coupon.image_url).filter((image) => image);
  }

  async getUserReviews() {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reviewApiEndPoint}?spa_id=${this.pathParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createUserReview(formData: any) {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUserReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async gotoLogin() {
    const authToken = await getStorageData('authToken');

    if (!authToken) {
      const message: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        goBack: 'LandingPageVenue',
        param: this.pathParam
      });
      this.send(message);
    }
  }

  setShowCreateReviewModal(value: boolean) {
    this.setState({ showCreateReviewModal: value })
  }


  storeSelectedTimeSlot = (price: string, id: number) => {
    const { services } = this.state.userDetails?.attributes || {};
    const { selectedTimeSlot } = this.state;
    const { attributes } = this.state.userDetails || {};
    const accountId = attributes?.profile_data?.attributes?.account_id;
    const catelogueId = this.state.selectedCatalogue?.id;

    if (selectedTimeSlot) {
      const subcategoryName = services?.find(service =>
        service.subcategories_with_slots.some(subcategory =>
          subcategory.slots.some(slot => slot.price === price)
        )
      )?.subcategories_with_slots.find(subcategory =>
        subcategory.slots.some(slot => slot.price === price)
      )?.subcategory.name ?? null;

      const [startTime, endTime] = selectedTimeSlot.slot.time.split(' - ');

      const slotInfo = {
        date: selectedTimeSlot.date,
        time: selectedTimeSlot.slot?.time,
        start_time: startTime,
        end_time: endTime,
        duration: selectedTimeSlot.slot?.duration,
        price: price,
        subcategoryName: subcategoryName,
        slotId: id,
        accountId: accountId,
        catelogueId: catelogueId
      };

      setStorageData('selectedTimeSlotPrice', JSON.stringify(slotInfo));
      this.setState({ selectedTimeSlotPrice: slotInfo }, () => {
      });
    }
  };

  formatTime(time: string): string {
    const upperCaseTime = time.toUpperCase();
    if (upperCaseTime.includes('AM') || upperCaseTime.includes('PM')) {
      return upperCaseTime;
    }

    const [hours, minutes] = time.split(':');
    let parsedHours = parseInt(hours);
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    parsedHours = parsedHours % 12 || 12;
    return `${parsedHours.toString().padStart(2, '0')}:${minutes} ${period}`;
  }


  initializeSelectedSubcategory = async () => {
    const { services } = this.state.userDetails?.attributes || {};

    if (services && services.length > 0 && services[0].subcategories_with_slots.length > 0) {
      const initialService = services[0].subcategories_with_slots[0];
      this.handleButtonClick(0, initialService.subcategory.id, initialService.subcategory.name);
      const firstSubcategoryId = services[0].subcategories_with_slots[0].subcategory.id;
      this.setState({
        selectedSubcategoryId: firstSubcategoryId,
        buttonIndex: 0
      }, () => {
        setStorageData('selectedID', JSON.stringify(firstSubcategoryId));
        this.getCataloguesDetails(firstSubcategoryId);
      });
    }
  }

  getCataloguesDetails = async (subcategoryId: number) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cataloguesApiEndPoint}/${subcategoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleButtonClick = async (index: number, id: number, name: string) => {
    const { selectedCatalogue } = this.state;
    const newSlotsAvailables = selectedCatalogue ? selectedCatalogue.availabilities[0]?.timeslots : [];

    await new Promise<void>((resolve) => {
      this.setState({
        buttonIndex: index,
        selectedSubcategoryId: id,
        newSlotsAvailable: newSlotsAvailables,
        selectServiceName: name
      }, resolve);
    });

    setStorageData('selectedID', JSON.stringify(id));
    await this.getCataloguesDetails(id);
  };

  gotoBookingDetails = async (id: number | undefined) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingDetails)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(message);
  };

  setCatalogueChanged = (value: boolean) => this.setState({ catalogueChanged: value });

  async postCustomerBookingDetails(bookingData: any) {
    const authToken = await getStorageData('authToken');
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBookingsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bookingData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleProfileClick = () => {
    this.props.navigation.navigate('SpaUserProfile');
  };

  updateReorderedDates = () => {
    const { selectedCatalogue } = this.state;

    if (!selectedCatalogue || !selectedCatalogue.availabilities) {
      this.setState({ reorderedDates: [], currentDate: '' });
      return;
    }

    const catalogueDates = selectedCatalogue.availabilities[0].dates;
    const catalogueDays = catalogueDates.map(date => new Date(date).getDay());

    const todays = new Date();
    let startDate;
    if (todays.getDay() === 1) {
      startDate = todays;
    } else {
      startDate = new Date(todays.setDate(todays.getDate() - (todays.getDay() - 1)));
    }
    const endDate = new Date(startDate.getTime() + 90 * 24 * 60 * 60 * 1000);

    let datesRange = [];
    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      if (catalogueDays.includes(d.getDay())) {
        datesRange.push(`${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`);
      }
    }

    datesRange = datesRange.filter(date => new Date(date).getDate() !== 11);

    const initialDate = datesRange.length > 0 ? datesRange[0] : '';

    this.setState({ reorderedDates: datesRange, currentDate: initialDate });
  }

  formatISODateString(dateString: any) {
    const cleanedDateString = dateString.split(' ')[0];
    return cleanedDateString;
  }

  formatBookedSlots(bookedSlots: any) {
    return bookedSlots
      .filter((slot: any) => slot.status === 'booked')
      .map((slot: any) => {
        const startTimeString = this.formatISODateString(slot.start_time);
        const endTimeString = this.formatISODateString(slot.end_time);

        const startTime = new Date(startTimeString);
        const endTime = new Date(endTimeString);
        if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
          return {
            booking_date: slot.booking_date,
            time_range: 'Invalid Date - Invalid Date'
          };
        }
        const formatTime = (date: any) => {
          const hours = date.getUTCHours();
          const minutes = date.getUTCMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const formattedHours = hours % 12 || 12;
          const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
          return `${formattedHours}:${formattedMinutes} ${ampm}`;
        };

        return {
          booking_date: slot.booking_date,
          time_range: `${formatTime(startTime)} - ${formatTime(endTime)}`
        };
      });
  }

  gotoBookingDetail = async (id = "preview") => {
    const { userDetails } = this.state;
    if (!userDetails?.attributes) {
      return;
    }

    const { attributes } = userDetails;
    const bookingInfo = {
      full_name: attributes.full_name,
      phone_number: attributes.phone_number,
      email: attributes.email,
      image_url: attributes.image_url,
      currency: attributes.currency,
      complete_attributes: attributes.profile_data.attributes,
    };

    try {
      await setStorageData('bookingInfo', JSON.stringify(bookingInfo));

      const message = new Message(
        getName(MessageEnum.NavigationBookingDetails)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(message);
    } catch (error) {
    }
  };

  async detectTimeZone() {
    let userTimeZone = momentTz.tz.guess();

    try {
      const response = await fetch('https://worldtimeapi.org/api/ip');
      const data = await response.json();
      userTimeZone = data.timezone;
    } catch (error) {
      userTimeZone = 'Asia/Kolkata';
    }

    this.setState({ detectedTimeZone: userTimeZone });
  }

  filterPassedSlots = (slots: any[], newDate: string, openTiming: string | undefined) => {
    if (!openTiming) {
      return slots;
    }
    const [openTime, closingTime] = openTiming.split(' - ');
    const now = momentTz().tz(this.state.detectedTimeZone);
    const selectedDateTime = momentTz(newDate).tz(this.state.detectedTimeZone);
    const closingTimeMoment = selectedDateTime.clone().set({
      hour: moment(closingTime, 'hh:mm a').get('hour'),
      minute: moment(closingTime, 'hh:mm a').get('minute'),
      second: 0,
      millisecond: 0
    });

    if (selectedDateTime.isBefore(now, 'day')) {
      return [];
    }

    if (selectedDateTime.isSame(now, 'day')) {
      if (now.isAfter(closingTimeMoment)) {
        return [];
      }
      return slots.filter(slot => {
        const [startTime] = slot.time.split(' - ');
        const slotTime = selectedDateTime.clone().set({
          hour: moment(startTime, 'hh:mm a').get('hour'),
          minute: moment(startTime, 'hh:mm a').get('minute'),
          second: 0,
          millisecond: 0
        });
        return slotTime.isAfter(now);
      });
    }

    // For future dates, return all slots
    return slots;
  };

  updatePaginatedVenues = () => {
    const { currentPage, venuesPerPage, venuesData } = this.state;
    const indexOfLastVenue = currentPage * venuesPerPage;
    const indexOfFirstVenue = indexOfLastVenue - venuesPerPage;
    const paginatedVenues = venuesData.slice(indexOfFirstVenue, indexOfLastVenue);
    this.setState({ paginatedVenues });
  }

  getFormattedSlots(): any[] {
    const { selectedCatalogue } = this.state;
    const catalogueKey = selectedCatalogue?.booked_slots?.map(slot => slot.booking_date).join(',') || '';

    if (!this.formattedSlotsCache[catalogueKey]) {
      this.formattedSlotsCache[catalogueKey] = this.formatBookedSlots(selectedCatalogue?.booked_slots || []);
    }

    return this.formattedSlotsCache[catalogueKey];
  }

  normalizeTimeFormat(time: string): string {
    time = time.trim().toUpperCase();
    const match = time.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/);
    if (!match) return time;

    let [, hours, minutes, period] = match;
    let hourNum = parseInt(hours);
    if (period === 'PM' && hourNum !== 12) hourNum += 12;
    if (period === 'AM' && hourNum === 12) hourNum = 0;

    return `${hourNum.toString().padStart(2, '0')}:${minutes}`;
  }

  isSlotBooked(slotTime: string, newDate: string): boolean {
    const normalizedSlotTime = this.normalizeTimeFormat(slotTime);
    const formattedSlots = this.getFormattedSlots();

    return formattedSlots.some((bookedSlot: { booking_date: string; time_range: string; }) => {
      const [bookedStart, bookedEnd] = bookedSlot.time_range.split(' - ');
      const normalizedBookedStart = this.normalizeTimeFormat(bookedStart);
      const normalizedBookedEnd = this.normalizeTimeFormat(bookedEnd);

      return bookedSlot.booking_date === newDate &&
        (normalizedSlotTime >= normalizedBookedStart && normalizedSlotTime < normalizedBookedEnd);
    });
  }

  checkIfOpen = async () => {
    const { open_timing } = this.state.userDetails?.attributes || {};
    if (!open_timing) return 'Unknown';

    const [openTime, closeTime] = open_timing.split(' - ');

    const now = momentTz().tz(this.state.detectedTimeZone);
    const open = momentTz.tz(openTime, 'h:mm a', this.state.detectedTimeZone);
    const close = momentTz.tz(closeTime, 'h:mm a', this.state.detectedTimeZone);

    open.year(now.year()).month(now.month()).date(now.date());
    close.year(now.year()).month(now.month()).date(now.date());

    if (close.isBefore(open)) {
      close.add(1, 'day');
    }

    const isOpen = now.isBetween(open, close, null, '[]'); 
    this.setState({ isOpen });
  }
  // Customizable Area End
}
