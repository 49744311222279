import React from "react"
// Customizable Area Start
import './SubCategories.css'
import { AppHeader } from '../../../components/src/AppHeader.web'

import { AppFooter } from '../../../components/src/AppFooter.web'
import LandingPageController, { configJSON } from "./LandingPageController"
import { imgI147504895236797486HTpe556x7cBoLvN2ZuRRme, image_venue, image_venue_1, image_venue_2, image_venue_3, image_venue_4, image_venue_5, image_star, left_arrow, right_arrow, categoryDefaultImage } from './assets'
import { url } from "inspector"
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web"
import { getStars } from '../../../components/src/helpers'
const baseURL = require('../../../framework/src/config.js').baseURL;
import { Box, Button, Card, CardContent, CardMedia, Typography, Grid, Pagination } from '@mui/material';
import Loader from "../../../components/src/Loader.web";

interface Props {
  navigation: any;
  id: string
}

export default class SubCategories extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.setState({ loading: true });
    this.getSubCategoriesData();
    this.setState({ loading: false });
  }

  navigationMap = {
    Home: 'LandingPage',
  };

  handleNavigate = (route: string) => {
    this.props.navigation.navigate(route);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => {
      this.updatePaginatedVenues();
      window.scrollTo(0, 0);
    });
  };

  render() {
    const { imageLoading, paginatedVenues, totalVenues, venuesData, currentPage, venuesPerPage } = this.state;
    const pageCount = Math.ceil(totalVenues / venuesPerPage);

    return (
      <Box style={{ backgroundColor: "#E8F6F4" }}>
        <AppHeader onClickEvent={() => this.goToLogin()} />
        <Box>
          <div
            className="hero-section"
            style={{
              backgroundImage: imageLoading && venuesData?.[0]?.cat_image
                ? `url(${venuesData[0]?.cat_image})`
                : `url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`,
              paddingTop: 50,
              position: 'relative',
            }}
          >
            <div className="breadcrumb" style={{ marginTop: 10 }}>
              <BreadCrumbs
                items={['Home', `${venuesData && venuesData.length > 0 && venuesData[0].cat_name}`
                ]}
                targetColor="#FFFFFF"
                navigationMap={this.navigationMap}
                navigate={this.handleNavigate}
              />
            </div>
            <div
              style={{
                zIndex: 1,
                color: '#fff',
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: '48px',
                lineHeight: '56px',
                letterSpacing: '-1.5%',
                marginLeft: "8.8rem",
                marginTop: "50px"
              }}
            >
              <h1>{venuesData && venuesData.length > 0 && venuesData[0].cat_name}</h1>

            </div>
            <div
              style={{
                zIndex: 1,
                color: '#fff',
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: '48px',
                lineHeight: '56px',
                letterSpacing: '-1.5%',
                marginLeft: "8.8rem",
                marginTop: "50px"
              }}
            >
              <h1>{venuesData?.[0]?.cat_description}</h1>

            </div>
          </div>
        </Box>
        <Box sx={{ px: 20, mt: 5 }}>
          <Typography variant="h4" gutterBottom
            style={{
              fontFamily: 'Ubuntu',
              fontWeight: 700,
              fontSize: '36px',
              lineHeight: '44px',
              letterSpacing: '-1%',
              textTransform: 'none'
            }}
          >
            Top Hair Service Venues
          </Typography>
          <Typography variant="body1"
            style={{
              fontFamily: 'Ubuntu',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              marginBottom: "30px"
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua
          </Typography>

          {this.state.loading ? (
            <Loader loading />
          ) : (
            <>
              <Grid container spacing={4}>
                {paginatedVenues && paginatedVenues.map((venue, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card style={{
                      height: "38rem", borderRadius: "10px",
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2), 10px 0 20px rgba(0, 0, 0, 0.3)',
                      border: 'none',
                    }}>
                      <Box
                        marginBottom={2}
                        style={{
                          background: `linear-gradient(
                            rgba(0, 0, 0, 0.2), 
                            rgba(0, 0, 0, 0.5)
                          ), url(${venue.images || venue.cat_image || categoryDefaultImage}) center center / cover no-repeat`,
                          width: '100%',
                          height: "20rem",
                          boxSizing: 'border-box',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Button variant="contained"
                          style={{
                            backgroundColor: "#E8F6F4",
                            padding: 8,
                            marginTop: "16rem",
                            width: "90%",
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            cursor: "pointer"
                          }}
                          onClick={() => this.goToVenue(venue.spa_id)}
                        >
                          <Typography style={{
                            color: "#398378",
                            fontFamily: "Ubuntu",
                            fontWeight: 700,
                            fontSize: 16,
                            textTransform: 'none'
                          }}>Book Now
                          </Typography>

                        </Button>
                      </Box>

                      <CardContent>
                        <Typography
                          style={{
                            fontFamily: "Ubuntu",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {venue.spa_name ? venue.spa_name : "The Massage Studio"}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Ubuntu",
                            fontWeight: 400,
                            fontSize: 14,
                            color: "#334155",
                            marginTop: "10px"
                          }}
                        >
                          {venue.address ? venue.address : "735 Nicholson Street, Canada"}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <Typography style={{ width: "24px", height: "24px", display: "flex", alignItems: "center" }}>
                            {getStars(venue.rating)} ({venue.rating.toFixed(1)})
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              fontFamily: 'Ubuntu',
                              fontWeight: 400,
                              fontSize: '14px',
                              lineHeight: '22px',
                              marginBottom: '8px',
                              marginTop: "5px"
                            }}>
                            {venue.description ? venue.description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..."}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "#398378",
                            borderRadius: "8px",
                            marginTop: "20px"
                          }}>
                          <Typography
                            style={{
                              fontSize: '14px',
                              lineHeight: '22px',
                              marginBottom: '8px',
                              fontFamily: 'Ubuntu',
                              fontWeight: 400,
                              color: "#FFFFFF",
                              padding: "20px"
                            }}
                          >
                            {venue.cat_name}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={this.handlePageChange}
                  sx={{ color: '#398378' }}
                />
              </Box>
            </>
          )}
        </Box>
        <AppFooter navigation={this.props.navigation} />
      </Box>
    );

  }
}
