import React from 'react'
import {
  Box,
  Link,
  Typography,
} from "@material-ui/core";
import { img1475048885HTpe556x7cBoLvN2ZuRRme, imgFacebook, imgInst, imgTwitter, imgYouTube } from './assets';

export function AppFooter({ navigation }: { navigation: any }) {
  const goToTermsAndCondition = () => navigation.navigate('TermsConditions');

  return (
    <>
      <Box
        style={{
          backgroundColor: '#398378ff',
          padding: '40px 141px'
        }}
        data-view-name={{ viewName: 'group_footer' }}
      >
        <Box>
          <img
            style={{ width: 147, height: 60 }}
            src={img1475048885HTpe556x7cBoLvN2ZuRRme}
          />
        </Box>
        <hr color='#94A3B8' style={{ margin: '32px 0' }} />
        {/* footer bar */}
        <Box display='flex'>
          <Box minWidth='25%'>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                paddingBottom: '4px'
              }}
            >{`Explore`}</Typography>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Home`}</Link>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Subscriptions`}</Link>
          </Box>
          <Box minWidth='25%'>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                paddingBottom: '4px'
              }}
            >{`Location`}</Typography>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Spa in Moncton`}</Link>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Spa in Moncton`}</Link>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Spa in Moncton`}</Link>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Spa in Moncton`}</Link>
          </Box>
          <Box minWidth='25%'>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                paddingBottom: '4px'
              }}
            >{`Contact Us`}</Typography>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                paddingBottom: '4px'
              }}
            >{`Address: Lorem ipsum dolor sit amet`}</Typography>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                paddingBottom: '4px'
              }}
            >{`Mail: support @ sparoof`}</Typography>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                paddingBottom: '4px'
              }}
            >{`Phone: (22+) 123-4567-900`}</Typography>
            <Link
              style={{
                padding: '4px 0',
                display: 'block',
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                cursor: 'pointer'
              }}
            >{`Get Support`}</Link>
          </Box>

          <Box minWidth='25%' marginBottom='74px'>
            <a
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                marginBottom: '20px',
                cursor: 'pointer',
                display: 'block'
              }}
              onClick={goToTermsAndCondition}
            >{`Terms & Condition`}</a>
            <Box display='flex' gridGap='20px'>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgFacebook} alt="img-facebook" />
              </Link>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgTwitter} alt="img-facebook" />
              </Link>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgInst} alt="img-facebook" />
              </Link>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgYouTube} alt="img-facebook" />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box height={62} style={{ backgroundColor: '#000000' }} display='flex' alignItems='center' justifyContent='center'>
        <Typography
          style={{
            fontFamily: 'Ubuntu',
            fontWeight: 400,
            fontSize: 14,
            letterSpacing: 0,
            color: '#ffffffff',
            height: '14px'
          }}
        >{`© 2024 All rights reserved`}</Typography>
      </Box>
    </>
  )
}
