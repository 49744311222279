import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';

// Customizable Area Start
const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    fromCustomerProfile?: boolean;
    customStyles?: object;
    heading?: string;
    breadcrumbs?: string[];
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    coupons: Coupon[];
    id: any;
    error: string | null;
    price: number;
    showWarning: boolean;
    warningMinCartValue: number | null;
    warningCouponId: string | null;
    bookingId: number;
    isPreviewMode: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any
    // Customizable Area End
}

export interface Coupon {
    attributes: {
        id: any;
        title: any;
        description: any;
        code: any;
        discount_type: any;
        discount: any;
        valid_from: any;
        valid_to: any;
        min_cart_value: any;
        max_cart_value: any;
        created_at: any;
        updated_at: any;
        account: any;
        coupon_image: any;
    };
    id: string;
    title: string;
    description: string;
    code: string;
    discount_type: string;
    discount: string;
    valid_from: string;
    valid_to: string;
    min_cart_value: string;
    max_cart_value: string;
    created_at: string;
    updated_at: string;
    account: {
        id: string;
        first_name: string | null;
        last_name: string | null;
        full_phone_number: string;
        country_code: number;
        phone_number: number;
        email: string;
        activated: boolean;
        // Add other properties as needed
    };
    coupon_image: any; // Adjust the type based on your data
}

class CouponsController extends BlockComponent<Props, S, SS> {
    apiCouponsDetails = "";
    applyCouponsDetails = "";
    currency = '';

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        (async () => {
            this.currency = await getStorageData('currency');
        })();

        this.state = {
            coupons: [],
            id: null,
            error: null,
            price: 0,
            showWarning: false,
            warningMinCartValue: null,
            warningCouponId: null,
            bookingId: 0,
            isPreviewMode: false
        };
        this.goToBookingsPage = this.goToBookingsPage.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await this.getCouponsDetails();

        const timeSlotInfo = await getStorageData('selectedTimeSlotPrice');
        if (timeSlotInfo) {
            const slotInfo = JSON.parse(JSON.stringify(timeSlotInfo));
            this.setState({ price: parseFloat(slotInfo.price) });
        }

        const urlParams = new URLSearchParams(window.location.search);
        const idParam = urlParams.get('id');
        const isPreviewMode = idParam === 'preview';
        const id = isPreviewMode ? null : Number(idParam);

        this.setState({
            bookingId: id || 0,
            isPreviewMode
        });
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);

        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (responseJson) {
            if (apiRequestCallId === this.applyCouponsDetails) {
                await setStorageData("coupon_response", JSON.stringify(responseJson.data));
            }
            if (apiRequestCallId === this.apiCouponsDetails) {
                this.setState({ coupons: responseJson.data })
            }
        }

        if (errorResponse) {
            let errorMessage = 'Failed to fetch coupons';
            if (errorResponse.message) {
                errorMessage = errorResponse.message;
            }
            this.setState({ error: errorMessage });
        }
        // Customizable Area End
    }

    async getCouponsDetails() {
        try {
            const authToken = await getStorageData('authToken');
            const headers = {
                "Content-Type": "application/json",
                "token": authToken
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            if (!this.apiCouponsDetails) {
                this.apiCouponsDetails = requestMessage.messageId;
            }

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.addCouponsCode}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.userDetailsApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
        }
    }

    async applyCoupon() {
        try {
            const authToken = await getStorageData('authToken');
            const headers = {
                "Content-Type": "application/json",
                "token": authToken
            };

            const bodyData = {
                data: {
                    coupon_code_id: "10",
                    bx_block_appointment_management_booked_slot_id: 16
                }
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            if (!this.applyCouponsDetails) {
                this.applyCouponsDetails = requestMessage.messageId;
            }

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.appliedCouponAPIEndPoint}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.appliedCouponApiMethodType
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(bodyData)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
        }
    }

    getLogicalValue(firstValue: string | null, secondValue: React.ReactNode, thirdValue: React.ReactNode): React.ReactNode | null {
        return firstValue !== null && firstValue !== '' ? secondValue : thirdValue;
    }

    applyCouponHandler = async (minCartValue: string, couponId: string, code: string, discount: string) => {
        const { price, isPreviewMode } = this.state
        const minCartValueNumber = parseFloat(minCartValue);

        if (price < minCartValueNumber) {
            this.setState({
                showWarning: true,
                warningMinCartValue: minCartValueNumber,
                warningCouponId: couponId
            });
        } else {
            this.setState({
                showWarning: false,
                warningMinCartValue: null,
                warningCouponId: null
            });
            try {
                const couponData = { discount };
                await setStorageData('appliedCoupon', JSON.stringify(couponData))
                const id = isPreviewMode ? 'preview' : this.state.bookingId;
                this.goToBookingsPage(id);
            } catch (error) {
            }
        }
    };

    goToBookingsPage = (id: number | string) => {
        const url = `/BookingDetails/${id}`;
        const message: Message = new Message(
            getName(MessageEnum.NavigationBookingDetails)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
        window.location.href = url;
    }

}

export default CouponsController;
