import React from 'react'
import {
  Box,
  Button,
  Typography,
} from "@material-ui/core";

type Product = {
  id: number;
  image_url: string;
  name: string;
  shortDescription?: string;
};

export const GalleryBlock = ({
  products,
  narrowImages,
  buttons
}: {
  products: Product[],
  narrowImages?: boolean,
  buttons: {
    title: string,
    handler: (arg: number) => void
  }[]
}) => (
  <Box display='flex' flexWrap='wrap' justifyContent='space-around' gridGap='10px'>
    {products.map((product) => {
      return <Box
        style={{
          position: 'relative',
          borderRadius: '8px',
          width: '32%',
          height: 'fit-content',
          marginBottom: '24px'
        }}>
        <img
          style={{
            width: '100%',
            height: narrowImages ? '16vw' : '24vw',
            objectFit: 'cover',
            borderRadius: '8px'
          }}
          src={product.image_url}
        />
        <Box position='absolute' left={16} bottom={16}>
          <Box mb='10px'>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 24,
                color: '#ffffffff'
              }}
            >{product.name}</Typography>
            {product.shortDescription && <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 12,
                color: '#ffffffff',
                paddingBottom: '20px'
              }}
            >{product.shortDescription}</Typography>}
          </Box>
          {buttons.map((button, index) => <Button
            style={{
              padding: '10px',
              borderRadius: 8,
              backgroundColor: index ? '#398378ff' : '#e8f6f4ff',
              color: index ? '#ffffff' : '#398378ff',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              textTransform: 'none',
              marginRight: '16px'
            }}
            onClick={() => button.handler(product.id)}
          >
            {button.title}
          </Button>)}
        </Box>
      </Box>
    }
    )}
  </Box>
)
