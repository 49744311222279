import React from 'react';
import { imgStar } from './assets';
import CurrencyList from "currency-list";
import { getStorageData, setStorageData } from '../../framework/src/Utilities';

export function getStars(avgReview: number | null) {
    return avgReview ? Array.from(Array(Math.round(avgReview))).map((item, index) =>
        <img key={index} src={imgStar} alt='image star' />
    ) : ''
}

export const isPlural = (amount: number, word: string, ending = "s") =>
    amount === 1 ? word : word + ending;

export const findCurrByCode = (currCode: string) => Object.values(CurrencyList.getAll("en_US")).find(
    (curr: Record<string, string>) => {
        return curr.code === currCode
    }
)?.symbol || '$';

export const getAndSaveCurrency = (responseJson: any) => {
    const currCode = responseJson?.data?.attributes.currency;
    const currancy = findCurrByCode(currCode);
    setStorageData('currency', currancy);

    return currancy;
}
