import React from "react";
// Customizable Area Start
import { Box, Button, InputLabel, TextField, Modal, Typography, Snackbar, Popover, styled } from '@material-ui/core';
import { location, camera, personOutline, fileIcon, uploadImg, closeIcon, arrowImg, notification, couponImg, editIcon, setAvailablity, setting, logout, hocReporting, my_booking, subscription, reviewSpaNameIcon, arrowIcon, profileCover } from "./assets"
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";
import { AppHeader } from "../../../../packages/components/src/AppHeader.web";
import { AppFooter } from "../../../../packages/components/src/AppFooter.web";
import ClearIcon from '@material-ui/icons/Clear';

const options = [
  {
    name: "Business Information",
    img: personOutline,
    path: "BasicInformation"
  },
  {
    name: "My Bookings",
    img: my_booking,
    path: "SpaBooking"
  },
  {
    name: "Update and set Availability",
    img: setAvailablity,
    path: "EditAvailabilities",
  },
  {
    name: "Create Coupons",
    img: couponImg
  },
  {
    name: "Ad-hoc reporting",
    img: hocReporting
  },
  {
    name: "My Subscription",
    img: subscription,
    path: 'Subscriptionbilling'
  },
  {
    name: "Settings",
    img: setting,
    path: "SpaOwnerSettings",
  },
  {
    name: "Notifications",
    img: notification
  },
  {
    name: "Logout",
    img: logout
  }
]

const customerOptions = [
  {
    name: "Basic Information",
    img: personOutline,
    path: "CustomerInformation"
  },
  {
    name: "My Bookings",
    img: my_booking,
    path: "MyBookings"
  },
  {
    name: "Notifications",
    img: notification
  },
  {
    name: "Settings",
    img: setting,
    path: "CutomerSettings"
  },
  {
    name: "Coupon",
    img: couponImg,
    path: "CustomerCoupons"
  },
]

export const SpaProfileOptionsWrapper = (props: any) => {
  const { optionItem, isCustomer, handleNavigationToBasicInformation } = props;
  const isCouponOption = optionItem.name === "Coupon";
  const isLogoutOption = optionItem.name === "Logout";

  let textColor;
  if (isLogoutOption) {
    textColor = "#DC2626";
  } else if (isCustomer && isCouponOption) {
    textColor = "#0F172A";
  } else {
    textColor = "#0F172A";
  }

  return <Box data-testId="basic-link" className="spaProfileInfoContainer" onClick={handleNavigationToBasicInformation}>
    <div style={{ display: "flex" }}>
      <div style={{ background: "#E8F6F4", borderRadius: 8, width: 56, height: 56 }}>
        <img src={optionItem.img} height={24} width="24px" style={{ padding: 16 }} />
      </div>
    </div>
    <Typography className="spaOptionText" style={{ color: textColor }}>
      {optionItem.name}
    </Typography>
    <div>
      <div style={{ cursor: "pointer" }}>
        <img src={arrowImg} />
      </div>
    </div>
  </Box>
}
export const ModalWrapper = styled(Box)({
  position: "absolute",
  top: '50%',
  left: "50%",
  transform: 'translate(-50%, -50%)',
  width: "auto",
  maxWidth: '55%',
  boxShadow: '0px 4px 8px 0px #00000008',
  borderRadius: "8px 8px 32px 8px",
  padding: "20px 0px",
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  fontFamily: 'Ubuntu',
  outline: 'none',
  '& .rdp-column-container:nth-child(2) .rdp-cell:not(:nth-child(10n + 1))': {
    height: 0,
    visibility: 'hidden',
  },
  '& .rdp-text-overlay': {
    '& div': {
      display: "none"
    }
  },
  '& .rdp-column-container:nth-child(3)': {
    display: 'none'
  },
  '& .rdp-reticule': {
    display: 'none'
  },

  '& .MuiLinearProgress-bar': {
    backgroundColor: '#D97706',
  },
  "& .addTimingOpen:hover": {
    background: '#E8F6F4'
  },
  '& .sametimeAllDay': {
    width: 20,
    height: 20,
    borderRadius: 6,
    color: '#398378 !important'
  },
  '& .MuiSnackbarContent-root': {
    background: '#FEE2E2',
    color: '#DC2626',
    fontSize: '12px',
    fontFamily: 'Ubuntu',
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    width: '100%',
  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    justifyContent: 'start',
    marginTop: '20px'
  },
  '& .multiButtonContainer': {
    borderTop: "1px solid #E2E8F0",
    height: "85px",
    borderRadius: "0px 0px 32px 8px",
    padding: "24px",
    display: "flex",
    justifyContent: 'end',
    boxSizing: 'border-box'
  },
  '& .buttonCursor': {
    cursor: 'pointer'
  },
  '& .locationView[disabled]': {
    backgroundColor: '#d7ded6'
  },
  '& .locationView[disabled] p': {
    color: '#FFFFFF'
  },
  '& .locationView[disabled] svg': {
    color: '#FFFFFF'
  },
  '& .locationViewStyle': { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' },
  '& .fileView': { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  '& .documentView': {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    justifyContent: 'space-between', backgroundColor: 'var(--Primary, #398378)', padding: '16px'
  },
  '& .uploadedDocument': {
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '6px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    background: "#FFFFFF",
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '4px 8px',
    padding: '10px',
  },
  '& .removeItem': {
    display: 'flex',
    backgroundColor: '#E8F6F4',
    width: '56px', height: '56px',
    borderRadius: '8px', border: '1px solid #E8F6F4',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .ModalHeader': {
    borderBottom: '1px solid #E2E8F0',
    boxSizing: 'border-box',
    display: 'flex',
    height: "80px",
    padding: "24px 16px 24px 40px",
    justifyContent: "space-between"
  },
  '& .modalHeadingText': {
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
    textAlign: "left"
  },
  '& .modalBOdyContainer': {
    display: "flex", flexDirection: 'column', gap: 60
  },
  '& .modalBodyMain': {
    display: 'flex', flexDirection: "column", gap: 16, padding: '0px 40px 0px 40px'
  },
  '& .inputeStyle': {
    borderRadius: '8px',
    border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)',
    '& .Mui-disabled': {
      color: "#334155",
      fontFamily: 'Ubuntu',
      fontSize: 16,
      lineHeight: 24
    }
  },
  '& .buttonContainer': {
    background: "#398378",
    height: "56px",
    padding: '16px',
    gap: "8px",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: 'Ubuntu',
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none"
  },
  "& #inputinuserblock": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
    borderBottomRightRadius: '100%'
  },
  '& .testLabel': {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: 700,
    color: '#334155',
    fontFamily: 'Ubuntu',
    fontSize: 14,
  },
  '& .testStar': {
    color: 'red',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '5px'
  },
})
export const MainWrapper = styled(Box)({
  '& .profileNameContainer': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    height: "100px",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  '& .profilename': {
    color: "#FFFFFF",
    fontFamily: '"Ubuntu"',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: "-0.01em",
    textAlign: "center"
  },
  "& #inputinuserblock": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
    borderBottomRightRadius: '100%'
  },
  '& .profileimg': {
    position: 'absolute',
    width: "100%",
    height: "100%",
    borderRadius: "50%"
  },
  '& .breadCrumbText': {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
    color: "#94A3B8"
  },
  '& .bannerImg': {
    position: 'absolute',
    width: "100%",
    height: "100%",
    borderRadius: 10
  },
  "& .bannerprofileImg": {
    position: 'absolute',
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
  },

  '& .imgContainer': {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    gap: 12,
    alignItems: "center",
    height: "156px",
    width: "156px",
    background: "var(--Primary, #398378)",
    borderRadius: "50%",
    position: "absolute",
    top: "-50px",
    border: "4px solid #fff",
  },
  '& .editIconContainer': {
    cursor: 'pointer',
    position: 'absolute',
    right: '-2px',
    bottom: "6px",
    background: "#E8F6F4",
    padding: "12px",
    borderRadius: "50%"
  },
  '& .imgsuggestionText': {
    fontFamily: '"Ubuntu"',
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
    textHlign: "center",
    color: "#D9EEEB"
  },
  '& .userNameText': {
    fontFamily: "'Ubuntu'",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
  },
  '& .locationSummarytext': {
    fontFamily: "'Ubuntu'",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",

  },
  '& .spaProfileInfoContainer': {
    cursor: "pointer",
    boxShadow: "0px 2px 8px 0px #00000014",
    boxSizing: "border-box",
    marginBottom: "12px",
    display: 'flex',
    background: "#FFFFFF",
    height: "88px",
    padding: "16px 12px 16px 16px",
    gap: "16px",
    borderRadius: "8px 0px 0px 0px",
    opacity: "0px",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  "& .spaOptionText": {
    width: '100%',
    fontFamily: '"Ubuntu"',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    textAlign: 'left',
    color: "#0F172A"
  },
  "& .buttonContainer": {
    height: "44px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    background: "#398378",
    fontFamily: "'Ubuntu'",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: 'left',
    color: "#FFFFFF",
    textTransform: "none"
  },
  '& .dailogContainer': {
    width: "793px",
    height: "612px",
    borderRadius: "8px 8px 32px 8px"

  }
})

export const CustomPopover = styled(Popover)({
  "& #inputinuserblock": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
    borderBottomRightRadius: '100%'
  },
  '& .tooltip': {
    display: 'flex',
    flexDirection: 'column',
    background: "#fff",
    padding: "0px 2px 0px 2px",
    boxShadow: '0px 2px 4px 0px #00000026',
    height: '96px',
    width: "296px"
  },
  '& .popoverButton': {
    display: 'flex',
    justifyContent: 'flex-start',
    color: "#0F172A",
    alignItems: "center",
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fonWeight: '400 !important',
    lineHeight: '24px',
    textAlign: 'left',
    background: "#FFFFFF"
  },
  '& .popoverButton:hover': {
    background: "#D9EEEB"
  },


})
export const CustomTypography = styled(Typography)({
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .addLocation': {
    color: '#fff',
    fontFamily: 'Ubuntu',
    fontSize: '14px',
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .readAndAgree': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '12px'
  },
  '& .businessLicense': {
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#334155',
    alignSelf: 'center',
    textAlign: 'center'
  },
  '& .businessLicenseDesc': {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Ubuntu',
    color: '#334155',
    alignSelf: 'center'
  },
  '& .browseFile': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#F59E0B',
    marginLeft: '5px'
  },
  '& .addOther': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#398378',
    paddingLeft: '5px'
  },
  '& .licenseTxt': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    color: '#0F172A',
  },
  '& .fileSize': {
    fontSize: '14px',
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    color: '#64748B'
  }

})

// Customizable Area End


class SpaUserProfile extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigate = (route: string) => {
    this.props.navigation.navigate('LandingPage');
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isCustomer } = this.state;
    const {
      profile_data: profileData,
      full_name: SPAVenueName,
      carousal_images: carouselImages,
    } = this.state.userDetails?.attributes || {};

    const menuOptions = isCustomer ? customerOptions : options;

    return (
      <MainWrapper>
        <AppHeader showLogin={false} />
        <Box style={{ backgroundColor: "rgb(232, 246, 244)", padding: "50px 150px" }}>
          <Box style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)", background: "#F6F0FF", borderRadius: 10 }}>
            <Box style={{ position: "relative", background: "var(--Primary, #398378)", height: "213px", borderRadius: 10 }}>
              {carouselImages && carouselImages.length > 0 && <img
                id="profileBanner"
                src={
                  carouselImages[0].url
                }
                className="bannerImg"
              />}
              <div style={{ position: 'absolute', padding: "20px", zIndex: 10 }}>
                <Box display={"flex"} alignItems={"center"}>
                  <Box display={"flex"} alignItems={"center"}
                    onClick={() => this.handleNavigate('LandingPage')}
                    data-test-id="home-breadcrumb">
                    <Typography className="breadCrumbText" style={{ cursor: "pointer" }}>Home</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <img src={arrowIcon} />
                    <Typography className="breadCrumbText" style={{
                      fontWeight: 700,
                      color: "#FFFFFF"
                    }}>My Profile</Typography>
                  </Box>

                </Box>

              </div>
              <div className="profileNameContainer" style={isCustomer ? {
                height: '100%',
                background: `rgba(0, 0, 0, 0.3) url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
                zIndex: 0,
              } : {}}>
                <div style={{ marginTop: "4%", }}>
                  <Typography className="profilename">
                    {isCustomer ? "My Profile" : "Spa Profile"}
                  </Typography>
                </div>
              </div>
            </Box>
            <Box style={{ background: "#F6F0FF", margin: "0px 100px", paddingBottom: 100 }}>
              <div>
                <div style={{ display: "flex", height: '106px' }}>
                  <div style={{ position: 'relative' }}>
                    <div className="imgContainer">
                      <img
                        id="profileBanner"
                        src={
                          this.state.avatarImage
                        }
                        className="profileImg"
                      />
                      <>
                        <img src={camera} />
                        <div>
                          <Typography className="imgsuggestionText">jpg, GIF or PNG,<br></br>Max size of 800k</Typography>
                        </div>
                        <input
                          data-testId='profilepic'
                          accept="image/*"
                          id="inputinuserblock"
                          className="imgContainer"
                          onChange={this.handleProfilePicUpload}
                          type="file"
                        />
                      </>
                      {this.state.avatarImage && (
                        <div className="editIconContainer">
                          <div style={{ position: "relative" }}>
                            <img data-testId="editIcon" src={editIcon} width={"24px"} height={"24px"} onClick={(event: any) => this.setState({ anchorEl: event.currentTarget })} />
                            <CustomPopover
                              data-test-id='edit-logo-prompt'
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => {
                                this.setState({ anchorEl: null });
                              }}
                              anchorEl={this.state.anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                style: { width: '300px', height: 96 }
                              }}
                            >
                              <div className="tooltip" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button className="popoverButton" style={{ height: "48px", padding: '12px 16px 12px 16px', textTransform: 'none', fontWeight: 400 }} onClick={() => { }}>
                                  {isCustomer ? "Edit Photo" : "Edit Logo"}
                                  <input
                                    data-testId="editLogo"
                                    accept="image/*"
                                    id="inputinuserblock"
                                    className="imgContainer"
                                    onChange={this.handleProfilePicUpload}
                                    type="file"
                                  />
                                </Button>
                                <Button data-testId="removeLogo" className="popoverButton" style={{ height: "48px", padding: '12px 16px 12px 16px', textTransform: 'none', fontWeight: 400 }} onClick={this.handleRemoveProfilePic}> {isCustomer ? "Remove Photo" : "Remove Logo"}</Button>
                              </div>
                            </CustomPopover>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="" style={{ marginLeft: "200px", width: "55%", display: 'flex', alignItems: "flex-end", justifyContent: 'space-between' }}>
                    <div>
                      <Typography className="userNameText">
                        {SPAVenueName}
                      </Typography>
                      <Box display={'flex'} alignItems={"center"} style={{ gap: 8 }}>
                        <img src={location} height={20} />
                        <Typography className="locationSummarytext">
                          {profileData?.attributes.address}
                          <Typography>
                            {profileData?.attributes.city}
                            {profileData?.attributes.city && profileData?.attributes.country && ', '}
                            {profileData?.attributes.country}
                          </Typography>
                        </Typography>
                      </Box>
                    </div>
                    {!isCustomer && (
                      <div>
                        <Button className="buttonContainer" onClick={this.handleUpdateProfilePopup}>Update Spa Name</Button>
                      </div>
                    )}
                  </div>
                </div>
                <Box style={{ marginTop: 60 }}>
                  {menuOptions.map((item: any, index: number) =>
                    <SpaProfileOptionsWrapper
                      optionItem={item}
                      isCustomer={isCustomer}
                      lastIndex={index === menuOptions.length - 1}
                      handleNavigationToBasicInformation={() => {
                        item.path && this.handleNavigationToBasicInformation(item.path);
                      }} />
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
        <ModalWrapperComponent
          isOpen={this.state.updateProfilePopup}
          modalHeading="Update Spa Name"
          handleClose={this.handleUpdateProfilePopup}
          width={"55.07%"}
        >
          <Box className="modalBOdyContainer">
            <Box style={{}} className="modalBodyMain">
              <Box >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InputLabel className="testLabel" style={{}}>{"New Spa Name"}{" "}</InputLabel>
                  <InputLabel className="testStar">{"*"}</InputLabel>
                </Box>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  className="inputeStyle"
                  data-test-id="txtInputSpaName"
                  placeholder={"Enter Spa Name"}
                  value={this.state.spaName}
                  onChange={this.setSpaName}
                  error={this.state.spaNameError}
                />
                {this.state.spaNameError &&
                  <CustomTypography>
                    <Typography className="txtErrorMsg">
                      {"Please enter new spa name"}
                    </Typography>
                  </CustomTypography>}
              </Box>
              <Snackbar
                data-test-id='error-snackbar'
                open={Boolean(this.state.businessLicenseError)}
                message={"Please upload spa license"}
                key={'top' + 'right'}
                style={{ background: '#fee2e2' }}
              />
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InputLabel className="testLabel" style={{ color: "#334155", fontFamily: 'Ubuntu', fontSize: 14, fontWeight: 700 }}>{"License"}{" "}</InputLabel>
                  <InputLabel className="testStar">{"*"}</InputLabel>
                </Box>
                {Array.from(this.state.businessLicenseImage).length > 0 ? Array.from(this.state.businessLicenseImage).map((file: { type: string, name: string }) =>
                  <Box key={file.name} className="uploadedDocument">
                    <Box className="removeItem">
                      <img src={fileIcon} alt={""} style={{ width: '24px', height: '24px', objectFit: 'cover' }} />
                    </Box>
                    <CustomTypography style={{ width: '100%', paddingLeft: '0.5rem' }}>
                      <Typography className="licenseTxt">{"License"}</Typography>
                      <Typography className="fileSize">{`(File type - ${file.type?.split("/")[1]})`}</Typography>
                    </CustomTypography>
                    <Box>
                      <ClearIcon
                        data-test-id='clear-icon'
                        style={{
                          fontSize: '32px',
                          color: '#94A3B8',
                          cursor: 'pointer'
                        }}
                        onClick={(index: any) => {
                          const updatedFiles = [...this.state.businessLicenseImage];
                          updatedFiles.splice(index, 1);
                          this.setState({ businessLicenseImage: updatedFiles })
                        }
                        } />
                    </Box>
                  </Box>) :
                  <Box style={{ border: "1px solid #CBD5E1", borderRadius: 8, alignItems: 'center', boxSizing: "border-box", display: 'flex', justifyContent: "space-between", width: "100%", padding: '8px 4px 8px 4px' }}>
                    <Box style={{ position: 'relative', display: 'flex', gap: 12, alignItems: "center" }}>
                      <Box style={{ boxSizing: "border-box", borderRadius: 8, width: 56, height: 56, background: "#E8F6F4", padding: "16px" }}>
                        <img src={uploadImg} />
                      </Box>
                      <input
                        data-testId="Bussiness_licence"
                        accept="image/*"
                        id="inputinuserblock"
                        onChange={this.handleFileChange}
                        type="file"
                      />
                      <Box>
                        <Typography variant="body1" style={{
                          fontFamily: 'Ubuntu', fontWeight: 400,
                          fontSize: 16,
                          lineHeight: '24px', color: "#0F172A"
                        }}>Upload File</Typography>
                        <Typography variant="caption" style={{
                          fontFamily: 'Ubuntu', fontWeight: 400,
                          fontSize: 14,
                          lineHeight: '22px', color: "#64748B"
                        }}>(File type - .pdf, .jpg, .jpeg or .png)</Typography>
                      </Box>
                    </Box>
                  </Box>
                }
              </Box>
              <Box width={"70%"}>
                <Box className="webLink">
                  <InputLabel className="testLabel" style={{ color: "#334155", fontFamily: 'Ubuntu', fontSize: 14, fontWeight: 700 }}>{"Website Link"}{" "}</InputLabel>
                </Box>
                <TextField
                  data-test-id='web-link'
                  fullWidth={true}
                  variant="outlined"
                  className="inputeStyle"
                  placeholder="Enter website link"
                  value={this.state.webLink}
                  onChange={this.setWebLink}
                />
              </Box>
            </Box>
            <Box style={{
              borderTop: "1px solid #E2E8F0",
              height: "104px",
              borderRadius: "0px 0px 32px 8px",
              padding: "24px",
              display: "flex",
              justifyContent: 'end',
              boxSizing: 'border-box'
            }}>
              <Button className="buttonContainer" onClick={this.handleSubmitForReview}>Submit for Review</Button>
            </Box>
          </Box>
        </ModalWrapperComponent>
        <Modal
          open={this.state.isSpaNameReviewPopup}
        >
          <ModalWrapper style={{ position: 'relative', width: '36.11%', maxHeight: "700px", height: "auto" }} >
            <Box id="form-dialog-title" className="" style={{ borderBottom: 'none', position: "absolute", top: 12, right: 12 }}>
              <Box data-testId="review-close" style={{ cursor: 'pointer' }} onClick={this.handleSpaReviewPopupClose}><img src={closeIcon} /></Box>
            </Box>
            <Box pt={'50px'} pb={'25px'} display='flex' justifyContent={"center"} flexDirection={"column"} style={{ gap: 10 }}>
              <Typography data-testId="spaname_review" style={{ textAlign: 'center', fontFamily: 'Ubuntu', fontSize: 18, lineHeight: "26px", color: "#0F172A" }}>Hold on!  Spa Name is under review</Typography>
              <Box style={{ display: 'flex', justifyContent: "center" }}>
                <img src={reviewSpaNameIcon} />
              </Box>
            </Box>
          </ModalWrapper>
        </Modal>
        {/* footer */}
        <AppFooter navigation={this.props.navigation} />
      </MainWrapper>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

}
// Customizable Area Start
export const ModalWrapperComponent = (props: any) => {
  const {
    isOpen,
    children,
    handleClose,
    modalHeading,
    width,
    withoutHeader,
    styles,
    closeStyles
  } = props;
  return (
    <Modal open={isOpen}>
      <ModalWrapper style={{ width, height: "auto", paddingTop: width === "50%" ? 0 : 20, ...(styles || {}) }} >
        <Box id="form-dialog-title" {...(!withoutHeader && { className: "ModalHeader" })} style={closeStyles || {}}>
          <Typography className="modalHeadingText" style={{ borderBottom: 0 }}>{modalHeading}</Typography>
          <Box
            style={{
              cursor: 'pointer',
              ...(withoutHeader && {
                position: 'absolute',
                right: 0
              })
            }}
            onClick={handleClose}
          >
            <img src={closeIcon} />
          </Box>
        </Box>
        {children}
      </ModalWrapper>
    </Modal>
  )
}

export default SpaUserProfile;
export { SpaUserProfile }
// Customizable Area End
