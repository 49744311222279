export const generateDatesRange = (startDate: string, endDate: string) => {
  const currentDate = new Date();
  const providedDate = new Date(startDate);
  const startDateObj = providedDate > currentDate ? providedDate : currentDate;
  const endDateObj = new Date(endDate);

  const formatedDates = [];

  while (startDateObj <= endDateObj) {
    formatedDates.push(
      new Date(startDateObj).toLocaleString('UTC', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    );
    startDateObj.setDate(startDateObj.getDate() + 1);
  }

  return formatedDates;
};

export const addZeroToTime = (time?: number) => {
  const timeStr = String(time);
  return timeStr.length > 1 ? timeStr : `0${timeStr}`;
};

const getDayDef = (slotTime: number) => (slotTime >= 12 * 60 ? 'pm' : 'am');

export const calculateSlots = (
  openingHours: string,
  duration = '30mins',
  timeObj?: {
    timeSlots?: string[];
    dates?: string[];
    currentDate?: string;
  },
  firstTime?: boolean
) => {
  const { timeSlots, dates, currentDate } = timeObj || {};
  
  const dateDays = dates?.map((date) => {
    const openedDay = new Date(date).getDay();
    return firstTime ? openedDay : openedDay ? openedDay - 1 : 6;
  });
  const currentDay = new Date(String(currentDate)).getDay();
  const isWorkingDay = Boolean(dateDays?.includes(currentDay));

  const getTimeMinutes = (time: string) => {
    const [hours, mins] = time.split(':');
    return Number(hours) * 60 + Number(mins);
  };

  const convertToString = (time: string) => {
    const timeNum = Number(time);

    const countHours = timeNum / 60;
    const isSecondHalf = countHours > 12;
    const decreaser = Number(isSecondHalf && 12);

    const hours = Math.trunc(countHours - decreaser);
    const minutes = Math.round((countHours - Math.trunc(countHours)) * 60);

    // Consider edge range values - 12 for hours and 00 for minutes
    return `${String(addZeroToTime(hours || 12))}:${String(
      addZeroToTime(minutes || 0)
    )}`;
  };

  const ordered = false;

  const isMinute = /m/.test(duration);
  const [dFirst, dSecond] = duration.match(/\d+/g) || [];

  // Convert duration to minutes
  const durationMinutes =
    !dSecond && isMinute
      ? Number(dFirst)
      : Number(dFirst || null) * 60 + Number(dSecond || null);

  const [startTime, endTime] = openingHours.match(/\d+:\d+/g) || [];
  const [startMins, endMins] = [
    getTimeMinutes(String(startTime)),
    getTimeMinutes(endTime),
  ];
  const firstHalfDayTimeMins = 12 * 60 - startMins;
  const slotsNumber = Math.floor(
    (firstHalfDayTimeMins + endMins) / (durationMinutes || 1)
  );

  const defaultSlots = new Array(slotsNumber)
    .fill({
      time: `${startTime}-${endTime} am`,
      duration,
      ordered,
      isAvailable: false,
    })
    .map((_, index) => {
      const slotStartTime =
        getTimeMinutes(String(startTime)) + durationMinutes * index;
      const slotEndTime = slotStartTime + durationMinutes;

      const resStartTime = convertToString(String(slotStartTime));
      const resEndTime = convertToString(String(slotEndTime));

      const startDayDef = getDayDef(slotStartTime);
      const endDayDef = getDayDef(slotEndTime);

      const time = `${resStartTime} ${startDayDef} - ${resEndTime} ${endDayDef}`;
      const isAvailable = isWorkingDay && Boolean(timeSlots?.includes(time));
      return {
        time,
        duration,
        ordered,
        isAvailable,
      };
    });

  if (timeSlots) {
    const uniqueTimeSlots = timeSlots.filter(
      (timeSlot) => !defaultSlots.map((item) => item.time).includes(timeSlot)
    );

    const uniqueSlots = uniqueTimeSlots.map((slot) => ({
      time: slot,
      duration,
      ordered,
      isAvailable: isWorkingDay,
    }));

    return [...defaultSlots, ...uniqueSlots];
  }

  return defaultSlots;
};

export const toStringMethod = (date: string, time: string) =>
  new Date(`${date} ${time}`).toString();
