import React from "react";
import { Box, Button, Modal, Typography, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UserProfileBasicController, { Props } from '../UserProfileBasicController';
import { TypographyView } from '../../../ordermanagement1/src/styledFont';

const CustomButton = styled(Button)({

});

export default class ConfirmationModal extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { openConfirmationModal, setOpenConfirmationModal, modalConfig } = this.props;
        if (!setOpenConfirmationModal || !modalConfig) {
            return null;
        }

        const isDeleteScenario = modalConfig.confirmText === 'Delete Account';

        return (
            <Modal
                data-test-id='confirmation-modal'
                open={Boolean(openConfirmationModal)}
                onClose={() => setOpenConfirmationModal(false)}
            >
                <TypographyView>
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '45%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px 8px 32px 8px',
                            outline: 'none',
                            maxWidth: '80%',
                            minWidth: '20rem',
                        }}
                    >
                        <Box p={2} borderBottom='1px solid #E2E8F0'>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <CloseIcon
                                    data-test-id='review-close-btn'
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto'
                                    }}
                                    onClick={() => setOpenConfirmationModal(false)}
                                />
                            </Box>
                            <Box px={5} py={2}>
                                <Typography
                                    style={{
                                        fontSize: '24px',
                                        fontWeight: 700,
                                        marginBottom: '8px'
                                    }}
                                    data-test-id='modal-title'

                                >
                                    {modalConfig.title}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        paddingBottom: '2px'
                                    }}
                                    data-test-id='modal-message'
                                >
                                    {modalConfig.message}
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={3}>
                            <Box
                                display='flex'
                                justifyContent='flex-end'
                                width='100%'
                            >
                                {isDeleteScenario ? (
                                    <>
                                        <CustomButton
                                            data-test-id='delete-account-btn'
                                            type='button'
                                            variant="contained"
                                            color="primary"
                                            className="declineButton"
                                            style={{
                                                fontWeight: 700,
                                                lineHeight: "24px",
                                                fontFamily: "Ubuntu",
                                                fontSize: '16px',
                                                marginRight: '1rem',
                                                width: '30%',
                                                whiteSpace: 'nowrap'
                                            }}
                                            onClick={() => modalConfig.onConfirm()}
                                        >
                                            {modalConfig.confirmText}
                                        </CustomButton>
                                        <CustomButton
                                            data-test-id='keep-account-btn'
                                            variant="contained"
                                            color="primary"
                                            className="agreeButton"
                                            style={{
                                                fontWeight: 700,
                                                lineHeight: "24px",
                                                fontFamily: "Ubuntu",
                                                fontSize: '16px',
                                                width: '30%',
                                                whiteSpace: 'nowrap'
                                            }}
                                            onClick={() => setOpenConfirmationModal(false)}
                                        >
                                            {modalConfig.cancelText}
                                        </CustomButton>
                                    </>
                                ) : (
                                    <>
                                        {modalConfig.cancelText && (
                                            <CustomButton
                                                data-test-id='cancel-btn'
                                                type='button'
                                                variant="contained"
                                                color="primary"
                                                className="declineButton"
                                                style={{
                                                    fontWeight: 700,
                                                    lineHeight: "24px",
                                                    fontFamily: "Ubuntu",
                                                    fontSize: '16px',
                                                    marginRight: '1rem',
                                                    width: "190px"
                                                }}
                                                onClick={() => setOpenConfirmationModal(false)}
                                            >
                                                {modalConfig.cancelText}
                                            </CustomButton>
                                        )}
                                        <CustomButton
                                            data-test-id='confirm-btn'
                                            variant="contained"
                                            color="primary"
                                            className="agreeButton"
                                            style={{
                                                fontWeight: 700,
                                                lineHeight: "24px",
                                                fontFamily: "Ubuntu",
                                                fontSize: '16px',
                                                width: "190px"
                                            }}
                                            onClick={() => modalConfig.onConfirm()}
                                        >
                                            {modalConfig.confirmText}
                                        </CustomButton>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </TypographyView>
            </Modal>
        );
    }
}